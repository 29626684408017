import { Box, Breadcrumbs, Container, Divider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import NewProductForm from './NewProductForm';

export default function CreateProducts() {
  return (
    <Container maxWidth="xl">
      <Box>
        <Typography variant="h4" sx={{ mb: 1 }}>
          Create Product
        </Typography>
        <Box>
          <Breadcrumbs aria-label="breadcrumb">
            <Link style={{ textDecoration: 'none', color: 'gray' }} underline="hover" color="inherit" to="/app">
              Dashboard
            </Link>
            <Link style={{ textDecoration: 'none', color: 'gray' }} underline="hover" color="inherit" to="/products">
              List
            </Link>
            <Typography color="text.primary">Create Product</Typography>
          </Breadcrumbs>
        </Box>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Divider />
      </Box>

      <Box sx={{ mt: 5 }}>
        <NewProductForm />
      </Box>
    </Container>
  );
}
