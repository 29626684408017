export function newCannedQuestionFormValidation(question, answer, product) {
  const error = { message: '' };

  // Check if email and password exist
  if (!question || question.length <= 2) {
    error.message = 'Question is missing';
    return error;
  }

  if (!answer || answer.length <= 2) {
    error.message = 'Answer is missing';
    return error;
  }

  if (!product || product.length <= 2) {
    error.message = 'Please select a Product';
    return error;
  }
  // If the form is valid, return an error object with an empty message
  error.message = '';
  return error;
}
