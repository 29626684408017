import { Box, Card, CardContent, Typography } from '@mui/material';

export default function NoTallyData() {
  return (
    <Box>
      <Card sx={{ height: '52vh' }}>
        <CardContent className="flex justify-center align-center column" sx={{ height: '100%' }}>
          <Box className="flex justify-center align-center column">
            <img
              src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/aimedinfo/pie-cjhart.svg"
              alt="pie-chart"
              style={{ width: '50%' }}
            />
            <Typography>No data yet</Typography>
            <Typography sx={{ fontSize: '12px' }} className="text-center">
              Currently, there is no data available for this chart. Please wait for chats to occur before the data
              becomes visible.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
