// @mui
import { Box, Button, Card, CardHeader, Divider, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// utils

// components
import Scrollbar from '../../../components/scrollbar';
import SelectedChat from '../../../pages/dashboard/home/helpers/SelectedChat';

// ----------------------------------------------------------------------

AppNewsUpdate.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppNewsUpdate({ title, subheader, list, ...other }) {
  const navigate = useNavigate();
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {list &&
            list.map((data, index) => (
              <Box key={index}>
                <SelectedChat data={data} />
              </Box>
            ))}
        </Stack>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: 'right' }}>
        <Button size="small" color="inherit" onClick={() => navigate('/chat')}>
          View all
        </Button>
      </Box>
    </Card>
  );
}
