import { STATS_LAST_SEVEN_DAY_COUNT, PRODUCT_COUNT, PRODUCTS_TALLY, CHAT_DATA, FULL_CHAT_DATA } from '../types';

const initialState = {
  lastSevenDayChats: [],
  lastSevenDayChatsLoading: true,
  productsTally: [],
  productsTallyLoading: true,
  chats: [],
  chatsLoading: true,
  patientChats: 0,
  hcpChats: 0,
  fullChatData: [],
  fullChatDataCount: 0,
  fullChatDataLoading: true,
  productsCount: 0,
  productsCountLoading: true,
  lastFiveProducts: [],
};

export default function Caller(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_COUNT:
      return {
        ...state,
        productsCount: action.payload.product_count,
        lastFiveProducts: action.payload.last_five_results,
        productsCountLoading: false,
      };
    case STATS_LAST_SEVEN_DAY_COUNT:
      return {
        ...state,
        lastSevenDayChatsLoading: false,
        lastSevenDayChats: action.payload,
      };
    case FULL_CHAT_DATA:
      return {
        ...state,
        fullChatData: action.payload.chats,
        fullChatDataCount: action.payload.total,
        fullChatDataLoading: false,
      };
    case CHAT_DATA:
      return {
        ...state,
        chats: (action.payload && action.payload.chats) || [],
        patientChats: (action.payload && action.payload.patient_count) || 0,
        hcpChats: (action.payload && action.payload.hcp_count) || 0,
        chatsLoading: false,
      };
    case PRODUCTS_TALLY:
      return {
        ...state,
        productsTally: action.payload,
        productsTallyLoading: false,
      };
    default:
      return state;
  }
}
