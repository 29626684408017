import { Box, Card, CardContent, CardHeader, CardActions, Skeleton, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { AppConversionRates, AppCurrentVisits } from '../../../../sections/@dashboard/app';
import { useGetProductChatTally } from '../../../../hooks/stats/useGetProductChatTally';
import NoTallyData from './NoTallyData';

export default function ProductTally({ type }) {
  const theme = useTheme();
  const { productsTally, productsTallyLoading } = useGetProductChatTally();
  const [products, setProducts] = useState();

  useEffect(() => {
    if (productsTally && productsTally.length >= 1) {
      const outputs = productsTally.map((element) => ({ label: element.product_name, value: element.chat_count }));
      setProducts(outputs);
    }
  }, [productsTally]);

  if (productsTallyLoading && type === 'some') {
    return (
      <Card sx={{ height: '50vh' }}>
        <CardHeader title={'Popular Products'} subheader={'Your Top 4 Highly Requested Products'} />

        <CardContent sx={{ mt: 2 }} className="flex justify-center align-center">
          <Skeleton animation="wave" variant="circular" width={220} height={220} />
        </CardContent>

        <CardActions sx={{ mt: 6 }}>
          <Skeleton animation="wave" height={20} width="100%" />
        </CardActions>
      </Card>
    );
  }

  if (productsTallyLoading && type === 'full') {
    return (
      <Card sx={{ height: '50vh' }}>
        <CardHeader title={'Product Chat Popularity Rankings'} subheader={'Upto 10 Product Chat Count'} />

        <CardContent sx={{ mt: 2 }} className=" justify-center align-center">
          <Box className=" justify-center align-center">
            <Skeleton animation="wave" height={20} width="100%" />
            <Skeleton sx={{ mt: 2 }} animation="wave" height={20} width="100%" />
            <Skeleton sx={{ mt: 2 }} animation="wave" height={20} width="100%" />
            <Skeleton sx={{ mt: 2 }} animation="wave" height={20} width="100%" />
            <Skeleton sx={{ mt: 2 }} animation="wave" height={20} width="100%" />
            <Skeleton sx={{ mt: 2 }} animation="wave" height={20} width="100%" />
            <Skeleton sx={{ mt: 2 }} animation="wave" height={20} width="100%" />
            <Skeleton sx={{ mt: 2 }} animation="wave" height={20} width="100%" />
          </Box>
        </CardContent>
      </Card>
    );
  }

  if (!productsTally || productsTally.length < 1) {
    return <NoTallyData />;
  }

  if (type === 'some') {
    const maxProductsToShow = 4;
    const chartData = products ? products.slice(0, Math.min(maxProductsToShow, products.length)) : [];

    return (
      <Box>
        <AppCurrentVisits
          title="Popular Products"
          subheader="Your Top 4 Highly Requested Products"
          chartData={chartData}
          chartColors={[
            theme.palette.primary.main,
            theme.palette.info.main,
            theme.palette.warning.main,
            theme.palette.error.main,
          ]}
        />
      </Box>
    );
  }

  return (
    <Box>
      {products && products.length >= 1 ? (
        <>
          <AppConversionRates
            title="Product Chat Popularity Rankings"
            subheader="Upto 10 Product Chat Count"
            chartData={products}
          />
        </>
      ) : (
        <>
          <NoTallyData />
        </>
      )}
    </Box>
  );
}
