import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { SwipeableDrawer } from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Label from '../../../../components/label';
import CustomLoader from '../../../../components/ui/CustomLoader';
import { formatString } from '../../../../helpers/formatString';
import { getRecentChatData } from '../../../../store/actions/stats/getRecentChatData';
import DrawerItem from '../../home/helpers/DrawerItem';
import ChatFilters from './ChatFilters';

const headCells = [
  {
    id: 'product',
    numeric: false,
    disablePadding: false,
    label: 'Product',
  },
  {
    id: 'chat',
    numeric: false,
    disablePadding: false,
    label: 'Chat',
  },
  {
    id: 'publish',
    numeric: true,
    disablePadding: false,
    label: 'Chat By',
  },
  {
    id: 'created_on',
    numeric: true,
    disablePadding: false,
    label: 'Date of Chat',
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ChatTable() {
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [filters, setFilters] = React.useState({});
  const [visibleRows, setVisibleRows] = React.useState([]);
  const [selectedChat, setSelectedChat] = React.useState({});

  const anchor = 'right';
  const [state, setState] = useState({
    right: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cannedQuestions = useSelector((state) => state.stats.fullChatData);
  const cannedQuestionsLoading = useSelector((state) => state.stats.fullChatDataLoading);
  const cannedQuestionsLength = useSelector((state) => state.stats.fullChatDataCount);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  React.useEffect(() => {
    console.log('filters', filters);

    dispatch(getRecentChatData(page, filters));
  }, [page, filters, dispatch]);

  const handleClickOpen = (row) => (event) => {
    setSelectedChat(row);
    toggleDrawer(anchor, true)(event);
  };
  const handleFilterDispatch = (filterPayload) => {
    setFilters(filterPayload);
    dispatch(getRecentChatData(page, filters));
  };
  React.useEffect(() => {
    setVisibleRows(cannedQuestions);
  }, [cannedQuestions]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  if (cannedQuestionsLoading) {
    return <CustomLoader />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
        }}
      >
        <ChatFilters page={page} onFilterApply={handleFilterDispatch} />
        {/* <EnhancedTableToolbar
          searchValue={searchValue}
          setSearch={setSearch}
          search={search}
          setSearchValue={setSearchValue}
          usersRemove={usersRemove}
          setUsersRemove={setUsersRemove}
          selectedUsersFinal={selectedUsersFinal}
          setSelectedUsersFinal={setSelectedUsersFinal}
          removeAll={removeAll}
          setRemoveAll={setRemoveAll}
        /> */}
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
            <EnhancedTableHead rowCount={cannedQuestions && cannedQuestions.length} />
            <TableBody>
              {visibleRows &&
                visibleRows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      onClick={handleClickOpen(row)}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell
                        style={{
                          maxWidth: '220px',
                        }}
                      >
                        <Box className="flex align-center">
                          <SquareRoundedIcon
                            style={{
                              fontSize: '30px',
                              borderRadius: '3px',
                              backgroundColor: row.product_color || 'white',
                              color: row.product_color || 'white',
                            }}
                          />
                          <Box sx={{ ml: 1 }}>
                            <Typography sx={{ fontSize: '14px' }}>
                              {formatString(row.product_name || '', 45)}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell
                        style={{
                          maxWidth: '240px',
                        }}
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        <Box className="flex align-center">
                          <HelpCenterIcon
                            style={{
                              fontSize: '40px',
                              borderRadius: '3px',
                              backgroundColor: 'white',
                              color: 'black',
                            }}
                          />
                          <Box sx={{ ml: 2 }}>
                            <Typography className="table-title-value-text">
                              {formatString(row.chat_data[0].chat || '', 40)}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell align="right">
                        <Box>
                          <Label color={'info'}>{row.nabp.length >= 1 ? 'HCP' : 'Patient'}</Label>
                          {row.nabp.length >= 1 ? (
                            <Box sx={{ mt: 1 }}>
                              <Label>NABP # {row.nabp}</Label>
                            </Box>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </TableCell>

                      <TableCell align="right">
                        <Box className="flex justify-end align-center">
                          <CalendarMonthIcon style={{ color: 'gray' }} />
                          <Typography className="basic-text" sx={{ ml: 1 }}>
                            {row.date_created}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[8]}
          component="div"
          count={cannedQuestionsLength || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />

      {selectedChat ? (
        <>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            <DrawerItem toggleDrawer={toggleDrawer} data={selectedChat} />
          </SwipeableDrawer>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
}
