import { toast } from 'react-hot-toast';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import {
  DELETE_PINECONE_PRODUCT_DOCUMENTS_API,
  DELETE_S3_DOCUMENT_API,
  PRODUCT_API,
  PRODUCT_DOCUMENTS_API,
} from '../../API';
import { DELETE_PRODUCT } from '../../types';

// get all product documents
export const allProductDocuments = async (productId) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const email = user.attributes.email;

    const response = await axios.get(PRODUCT_DOCUMENTS_API, {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      params: {
        email,
        productId,
      },
    });
    if (response.data) {
      console.log('product documents to delete', response.data);
      return response.data;
    }
    return [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

// delete pinecone namespace
export const deletePineconeNamespace = async (productId) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const email = user.attributes.email;

    const res = await axios.delete(DELETE_PINECONE_PRODUCT_DOCUMENTS_API, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      },
      params: {
        email,
        productId,
      },
    });
    console.log('Res pinecone', res);
    if (res && res.data && res.data.success === true) {
      console.log('res - deleting pinecone', res.data);
    } else {
      toast.error('Unable to delete documents related to product');
    }
  } catch (error) {
    console.error(error);
    toast.error('Unable to delete documents related to product');
  }
};

export const deleteS3Documents = async (documents) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;

  try {
    const res = await axios.get(DELETE_S3_DOCUMENT_API, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      },
      params: {
        email,
        documents: JSON.stringify(documents),
      },
    });
    if (res && res.data) {
      console.log('Deleting resonse from s3', res);
      res.data.forEach(async (item, index) => {
        await axios.delete(item.url);
      });
    }
    return res.data;
  } catch (err) {
    console.log(err);
    toast.error('Error deleting product documents');
    return [];
  }
};

export const deleteProductEntry = async (productId) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;

  console.log('Delete product entry - ', productId);
  try {
    const res = await axios.delete(PRODUCT_API, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      },
      params: {
        email,
        productId,
      },
    });
    if (res && res.data && res.data.success === true) {
      toast.success('Product successfully deleted');
    } else {
      toast.error('Error deleting product! Please try again');
    }
  } catch (err) {
    toast.error('Error deleting product! Please try again');
    if (err.response) {
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      console.log(err.request);
    } else {
      console.log('Error', err.message);
    }
  }
};

export const deleteProduct = (productId) => async (dispatch) => {
  const productDocuments = await allProductDocuments(productId);
  if (productDocuments.success === true) {
    const deleteDocuments = productDocuments.product_documents.map((doc) => ({
      id: doc.document_id,
      productId: doc.product_id,
      document: doc.document_name,
      success: true,
    }));
    if (productDocuments.product_documents.length >= 1) {
      await deleteS3Documents(deleteDocuments);
    }
    await deletePineconeNamespace(productId);
    await deleteProductEntry(productId);
    dispatch({
      type: DELETE_PRODUCT,
      payload: productId,
    });
  } else {
    toast.error('Error deleting your product! Please try again or contact us');
  }
};
