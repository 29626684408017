import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Accordion, AccordionDetails, AccordionSummary, Box, List, ListItemText, Typography } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon, StyledNavItemIconDot } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info, collapse, child } = item;
  const [currentURL, setCurrentURL] = useState('');
  useEffect(() => {
    const url = window.location.href;
    setCurrentURL(url);
  }, []);

  return collapse ? (
    <>
      <Accordion
        sx={{
          '&.active': {
            color: 'text.primary',
            bgcolor: 'red',
            fontWeight: 'fontWeightBold',
          },
          '&:before': {
            display: 'none',
          },
          background: currentURL === path ? 'red' : 'transparent',
        }}
      >
        <AccordionSummary
          sx={{ padding: '0px' }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
          <Typography sx={{ color: '#637381', fontSize: '14px', marginTop: '0px', marginBottom: '0px' }}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '0px' }}>
          {child &&
            child.length >= 1 &&
            child.map((item) => (
              <>
                <StyledNavItem
                  component={RouterLink}
                  to={item.path}
                  sx={{
                    '&.active': {
                      color: 'text.primary',
                      bgcolor: 'action.selected',
                      fontWeight: 'fontWeightBold',
                    },
                  }}
                >
                  <StyledNavItemIconDot style={{ color: '#637381' }}>{item.icon && item.icon}</StyledNavItemIconDot>

                  <ListItemText disableTypography primary={item.title} />
                </StyledNavItem>
              </>
            ))}
        </AccordionDetails>
      </Accordion>
    </>
  ) : (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
          height: '48px',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
