import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLastSevenDayChatCount } from '../../store/actions/stats/lastSevenDayChatCount';

export function useGetLastSevenDayChatCount() {
  const lastSevenDayChats = useSelector((state) => state.stats.lastSevenDayChats);
  const lastSevenDayChatsLoading = useSelector((state) => state.stats.lastSevenDayChatsLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    async function companyUsers() {
      try {
        await dispatch(getLastSevenDayChatCount());
      } catch (err) {
        console.log(err);
      }
    }
    companyUsers();
  }, []);

  return { lastSevenDayChats, lastSevenDayChatsLoading };
}
