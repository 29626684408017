import { Box, Container, IconButton, Typography } from '@mui/material';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

import CloseIcon from '@mui/icons-material/Close';
import useResponsive from '../../../../hooks/useResponsive';
import Label from '../../../../components/label';
import Chat from './Chat';
import { formatTimestamp } from '../../../../helpers/formatTimestamp';

export default function DrawerItem({ toggleDrawer, data }) {
  const isDesktop = useResponsive('up', 'md');
  const drawerSize = isDesktop ? '500px' : '350px';
  // const sortedChat = data?.chat?.sort((a, b) => a.id - b.id);

  return (
    <Container
      sx={{
        overflowY: 'scroll',
        height: '100%',
      }}
    >
      <Box
        sx={{
          maxWidth: drawerSize,
          width: drawerSize,
          mt: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <IconButton aria-label="delete" size="large" onClick={toggleDrawer('right', false, {})}>
          <CloseIcon />
        </IconButton>
        <Box>
          <Label color={'info'}>
            {data.nabp && data.nabp.length >= 1
              ? `HCP Started the Chat with NABP Number ${data.nabp}`
              : 'Patient Started the Chat'}
          </Label>
        </Box>
      </Box>

      <Box
        sx={{
          mt: 2,
          pb: 2,
        }}
        className="flex align-center"
      >
        <SquareRoundedIcon
          style={{
            fontSize: '50px',
            borderRadius: '3px',
            backgroundColor: (data && data.product_color) || 'white',
            color: (data && data.product_color) || 'white',
          }}
        />
        <Box sx={{ ml: 2 }}>
          <Typography sx={{ fontSize: '12px' }}>{data && data.id}</Typography>
          <Typography variant="h6">{(data && data.product_name) || ''}</Typography>
          <Typography sx={{ fontSize: '12px' }}>{formatTimestamp(data.date_created) || ''}</Typography>
        </Box>
      </Box>
      <Box>
        <Chat chatData={data.chat_data} drawerSize={drawerSize} />
      </Box>
    </Container>
  );
}
