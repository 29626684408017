import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCompanyProducts } from '../store/actions/products/getAllCompanyProducts';

export function useGetCompanyProducts() {
  const allCompanyProducts = useSelector((state) => state.companyProducts.allCompanyProducts);
  const allCompanyProductsLoading = useSelector((state) => state.companyProducts.allCompanyProductsLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    async function companyUsers() {
      try {
        await dispatch(getAllCompanyProducts());
      } catch (err) {
        console.log(err);
      }
    }
    companyUsers();
  }, []);

  return { allCompanyProducts, allCompanyProductsLoading };
}
