import toast from 'react-hot-toast';
import { Box, Button, Card, CardContent, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material';
import { MuiColorInput } from 'mui-color-input';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { newProductFormValidation } from '../../../../helpers/validation/newProductFormValidation';
import { createNewProduct } from '../../../../store/actions/products/createNewProduct';

export default function NewProductForm() {
  const [color, setColor] = useState('#ffffff');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const productTypes = [
    'Drug',
    'Medical Device',
    'Biologics',
    'Durable Medical Equipement (DME)',
    'OCT Supplements',
    'Other',
  ];

  const dispatch = useDispatch();

  const handleChangeColor = (color) => {
    setColor(color);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleClick = () => {
    const err = newProductFormValidation(name, description, color, type, phoneNumber);
    if (!err.message) {
      const productObj = {
        name,
        description,
        color,
        type,
        phoneNumber,
      };
      dispatch(createNewProduct(productObj));
      setName('');
      setColor('#ffffff');
      setDescription('');
      setType('');
      setPhoneNumber('');
    } else {
      toast.error(err.message || 'Error, please check all your fields');
    }
  };

  return (
    <>
      <Card sx={{ backgroundColor: 'transparent' }}>
        <CardContent>
          <Box sx={{ mt: 3 }}>
            <Typography className="regular-text" sx={{ mb: 1 }}>
              Product Name <span style={{ color: '#c67c7c' }}>(Required)</span>
            </Typography>
            <TextField
              value={name}
              fullWidth
              id="outlined-basic"
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
            />
          </Box>
          <Box sx={{ mt: 3 }}>
            <Typography className="regular-text" sx={{ mb: 1 }}>
              Product Description <span style={{ color: '#c67c7c' }}>(Required)</span>
            </Typography>
            <TextField
              value={description}
              fullWidth
              variant="outlined"
              multiline
              rows={3}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Box>
          <Box sx={{ minWidth: 120, mt: 3 }}>
            <Typography className="regular-text" sx={{ mb: 1 }}>
              Product Type <span style={{ color: '#c67c7c' }}>(Required)</span>
            </Typography>
            <FormControl fullWidth>
              <Select
                value={type}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={handleTypeChange}
              >
                {productTypes ? (
                  productTypes.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ mt: 2, width: '100%', mb: 3 }}>
            <Typography className="regular-text" sx={{ mb: 1 }}>
              Product Color <span style={{ color: '#c67c7c' }}>(Required)</span>
            </Typography>
            <MuiColorInput style={{ width: '100%' }} value={color} onChange={handleChangeColor} />
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography className="regular-text" sx={{ mb: 1 }}>
              Product Support Phone Number <span style={{ color: '#c67c7c' }}>(Required)</span>
            </Typography>
            <TextField
              placeholder="+1 xxx-xxx-xxxx"
              value={phoneNumber}
              fullWidth
              id="outlined-basic"
              variant="outlined"
              onChange={(e) => setPhoneNumber(e.target.value)}
              helperText="This contact number is dedicated to providing support, allowing individuals to report any adverse events or product complaints promptly."
            />
          </Box>
        </CardContent>
      </Card>
      <Box className="flex justify-end mt-6">
        <button
          type="submit"
          onClick={handleClick}
          className="inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 hover:from-violet-600 hover:to-blue-600 border border-transparent text-white text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-4 dark:focus:ring-offset-gray-800"
        >
          Create Product
        </button>
      </Box>
    </>
  );
}
