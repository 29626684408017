import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductCount } from '../../store/actions/stats/getProductCount';

export function useGetProductsCount() {
  const productsCount = useSelector((state) => state.stats.productsCount);
  const productsCountLoading = useSelector((state) => state.stats.productsCountLoading);
  const lastFiveProducts = useSelector((state) => state.stats.lastFiveProducts);

  const dispatch = useDispatch();

  useEffect(() => {
    async function companyUsers() {
      try {
        await dispatch(getProductCount());
      } catch (err) {
        console.log(err);
      }
    }
    companyUsers();
  }, []);

  return { productsCount, productsCountLoading, lastFiveProducts };
}
