import { combineReducers } from 'redux';
import userReducers from './userReducers';
import productsReducers from './productsReducers';
import productReducers from './productReducers';
import cannedReducers from './cannedReducers';
import companyProductsReducers from './companyProductsReducers';
import trainingReducers from './trainingReducers';
import documentReducers from './documentReducers';
import statsReducers from './statsReducers';

export default combineReducers({
  user: userReducers,
  products: productsReducers,
  product: productReducers,
  canned: cannedReducers,
  companyProducts: companyProductsReducers,
  training: trainingReducers,
  documents: documentReducers,
  stats: statsReducers,
});
