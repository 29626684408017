import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProductChatTally } from '../../store/actions/stats/getProductChatTally';

export function useGetProductChatTally() {
  const productsTally = useSelector((state) => state.stats.productsTally);
  const productsTallyLoading = useSelector((state) => state.stats.productsTallyLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    async function companyUsers() {
      try {
        await dispatch(getProductChatTally());
      } catch (err) {
        console.log(err);
      }
    }
    companyUsers();
  }, []);

  return { productsTally, productsTallyLoading };
}
