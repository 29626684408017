import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { Box, Stack, SwipeableDrawer, Typography } from '@mui/material';
import { Fragment, useState } from 'react';
import { formatTimestamp } from '../../../../helpers/formatTimestamp';
import DrawerItem from './DrawerItem';

export default function SelectedChat({ data }) {
  const anchor = 'right';

  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  return (
    <Fragment key={anchor}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{ cursor: 'pointer' }}
        onClick={toggleDrawer(anchor, true)}
      >
        <SquareRoundedIcon
          style={{
            fontSize: '30px',
            borderRadius: '3px',
            backgroundColor: (data && data.product_color) || 'white',
            color: (data && data.product_color) || 'white',
          }}
        />
        <Box sx={{ minWidth: 240, flexGrow: 1 }}>
          <Typography color="inherit" variant="subtitle2" noWrap>
            {data.product_name || ''}
          </Typography>

          <Typography
            variant="body2"
            sx={{ color: 'text.secondary' }}
            noWrap
            dangerouslySetInnerHTML={{ __html: data.chat_data[0].chat || '' }}
          />
        </Box>

        <Typography variant="caption" sx={{ pr: 3, flexShrink: 0, color: 'text.secondary' }}>
          {formatTimestamp(data.date_created)}
        </Typography>
      </Stack>

      <SwipeableDrawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
        onOpen={toggleDrawer(anchor, true)}
      >
        <DrawerItem toggleDrawer={toggleDrawer} data={data} />
      </SwipeableDrawer>
    </Fragment>
  );
}
