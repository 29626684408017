import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { Box, Button, Card, CardContent, CardHeader, Skeleton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatTimestamp } from '../../../../helpers/formatTimestamp';

export default function RecentFiveProducts({ lastFiveProducts, productsCountLoading }) {
  const navigate = useNavigate();
  const fiveNumbers = Array.from({ length: 5 }, (_, index) => index + 1);

  return productsCountLoading ? (
    <>
      <Card sx={{ height: '50vh' }}>
        <CardHeader title={'Recent Products'} subheader={'Your recently created products'} />

        <CardContent>
          {fiveNumbers.map((num, index) => (
            <Box className="flex" key={index} sx={{ mt: 1 }}>
              <Skeleton animation="wave" variant="circular" width={50} height={50} />
              <Box sx={{ width: '80%', ml: 2, mt: 1 }}>
                <Skeleton animation="wave" height={10} width="80%" />
                <Skeleton animation="wave" height={10} width="40%" />
              </Box>
            </Box>
          ))}
        </CardContent>
      </Card>
    </>
  ) : (
    <>
      <Card sx={{ height: '50vh' }}>
        <CardHeader title={'Recent Products'} subheader={'Your recently created products'} />

        <CardContent>
          {lastFiveProducts && lastFiveProducts.length >= 1 ? (
            <>
              {lastFiveProducts.map((item, index) => (
                <Box key={index} className="flex justify-center align-center" sx={{ mt: 2 }}>
                  <SquareRoundedIcon
                    style={{
                      fontSize: '30px',
                      borderRadius: '3px',
                      backgroundColor: item.product_color || 'white',
                      color: item.product_color || 'white',
                    }}
                  />
                  <Box sx={{ ml: 2, minWidth: 240, flexGrow: 1 }}>
                    <Typography color="inherit" sx={{ fontSize: '15px', fontWeight: 600 }} noWrap>
                      {item.product_name}
                    </Typography>
                    <Typography sx={{ fontSize: '12px', color: 'text.secondary' }}>
                      {formatTimestamp(item.date_created)}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <></>
          )}
          <Box className="flex justify-end align-center">
            <Button onClick={(e) => navigate('/products')}>View All</Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
