import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Breadcrumbs, Card, CardContent, CardHeader, Container, Divider, Grid, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { getProduct } from '../../../../store/actions/products/getProduct';
import EditCannedForm from './EditCannedForm';
import { getSingleCannedQuestion } from '../../../../store/actions/canned/getSingleCannedQuestion';
import CustomLoader from '../../../../components/ui/CustomLoader';

export default function EditCannedQuestion() {
  const { cannedId } = useParams();
  const dispatch = useDispatch();

  const cannedSingleQuestionLoading = useSelector((state) => state.canned.cannedSingleQuestionLoading);
  const cannedSingleQuestion = useSelector((state) => state.canned.cannedSingleQuestion);

  useEffect(() => {
    if (cannedId) {
      dispatch(getSingleCannedQuestion(cannedId));
    }
  }, [cannedId]);

  if (cannedSingleQuestionLoading) {
    return <CustomLoader />;
  }

  if (cannedSingleQuestion && cannedSingleQuestion.success === false) {
    return (
      <Container>
        <Box>
          <Typography variant="h4" sx={{ mb: 1 }}>
            Edit Canned Question
          </Typography>
        </Box>
        <Box>
          <Breadcrumbs aria-label="breadcrumb">
            <Link style={{ textDecoration: 'none', color: 'gray' }} underline="hover" color="inherit" to="/app">
              Dashboard
            </Link>
            <Link style={{ textDecoration: 'none', color: 'gray' }} underline="hover" color="inherit" to="/canned">
              Canned Question
            </Link>
            <Typography color="text.primary">Edit Canned Question</Typography>
          </Breadcrumbs>
        </Box>
        <Box sx={{ mt: 5 }}>
          <Card>
            <CardHeader
              title={'Canned Question Not Found! Please try again'}
              subheader={'Hello, the question your are looking for is not found. Please refresh the page, or try again'}
            />
            <CardContent>
              <Box className="flex justify-center align-center">
                <img src="/assets/illustrations/illustration_404.svg" alt="404" />
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography variant="h4" sx={{ mb: 1 }}>
          Edit Canned Question
        </Typography>
      </Box>
      <Box>
        <Breadcrumbs aria-label="breadcrumb">
          <Link style={{ textDecoration: 'none', color: 'gray' }} underline="hover" color="inherit" to="/app">
            Dashboard
          </Link>
          <Link style={{ textDecoration: 'none', color: 'gray' }} underline="hover" color="inherit" to="/canned">
            Canned Question
          </Link>
          <Typography color="text.primary">Edit Canned Question</Typography>
        </Breadcrumbs>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Divider />
      </Box>

      <Box sx={{ mt: 5 }}>
        <Grid container spacing={2}>
          <Grid item md={4} sx={{ width: '100%' }}>
            <Box>
              <Typography variant="h6">Details</Typography>
              <Typography className="regular-text">Question, Answer, Product...</Typography>
            </Box>
          </Grid>
          <Grid item md={8} sx={{ width: '100%' }}>
            <EditCannedForm question={cannedSingleQuestion.question} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
