import { toast } from 'react-hot-toast';
import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import FileSelectionLogic from './FileSelectLogic';
import SelectedFilesGrid from './SelectedFiles';
import TrainingInProgress from './TrainingInProgress';
import { trainDocuments } from '../../../../../store/actions/train/trainDocuments';

const CustomDropZone = ({ product }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [tempSelected, setTempSelected] = useState([]);
  const dispatch = useDispatch();
  const trainingInProgress = useSelector((state) => state.training.trainingInProgress);

  const [chunkedOutputs, setChunkedOutputs] = useState([]);
  const [chunkingInProgress, setChunkingInProgress] = useState(false);

  const handleDeleteFile = (fileToDelete) => {
    setChunkedOutputs((prevSelectedFiles) => prevSelectedFiles.filter((file) => file.file.name !== fileToDelete.name));
    setSelectedFile((prevSelectedFiles) => prevSelectedFiles.filter((file) => file.name !== fileToDelete.name));
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileSelect = (files) => {
    setSelectedFile(files);
  };

  const handleCancelTrain = () => {
    setSelectedFile([]);
    setTempSelected([]);
    setChunkedOutputs([]);
    setChunkingInProgress(false);
  };

  const handleTrainDocument = async () => {
    if (!chunkingInProgress && chunkedOutputs && !trainingInProgress) {
      dispatch(trainDocuments(product, chunkedOutputs));
      setSelectedFile([]);
      setTempSelected([]);
      setChunkedOutputs([]);
      setChunkingInProgress(false);
    } else {
      toast.error('Please make sure you have uploaded file(s) before training.');
    }
  };

  return (
    <>
      <Grid sx={{ pt: 1 }} container spacing={2} className="flex justify-center align-center">
        <Grid item md={12} xs={12}>
          <Grid container spacing={3} className="flex justify-center align-center">
            <Grid item md={12} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <FileSelectionLogic
                trainingInProgress={trainingInProgress}
                selectedFile={selectedFile}
                chunkedOutputs={chunkedOutputs}
                setSelectedFile={setSelectedFile}
                onSelect={handleFileSelect}
                setChunkedOutputs={setChunkedOutputs}
                handleDragOver={handleDragOver}
                setChunkingInProgress={setChunkingInProgress}
                tempSelected={tempSelected}
                setTempSelected={setTempSelected}
              />
            </Grid>
            <Grid item md={12} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
              <SelectedFilesGrid
                chunkingInProgress={chunkingInProgress}
                selectedFile={selectedFile}
                chunkedOutputs={chunkedOutputs}
                handleDeleteFile={handleDeleteFile}
                handleTrainDocument={handleTrainDocument}
                handleCancelTrain={handleCancelTrain}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TrainingInProgress />
    </>
  );
};

export default CustomDropZone;
