export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_CANNED_QUESTIONS = 'GET_CANNED_QUESTIONS';
export const GET_CANNED_QUESTION = 'GET_CANNED_QUESTION';

export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_DOCUMENTS = 'GET_PRODUCT_DOCUMENTS';

export const GET_ALL_COMPANY_PRODUCT = 'GET_ALL_COMPANY_PRODUCT';

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_CANNED_QUESTION = 'DELETE_CANNED_QUESTION';

export const CREATE_NEW_PRODUCT = 'CREATE_NEW_PRODUCT';

export const TRAINING_IN_PROGRESS = 'TRAINING_IN_PROGRESS';
export const TRAINING_COMPLETED = 'TRAINING_COMPLETED';
export const DOCUMENTS_DELETED = 'DOCUMENTS_DELETED';
export const DOCUMENT_DELETION_IN_PROGRESS = 'DOCUMENT_DELETION_IN_PROGRESS';
export const GET_COMPANY_DOCUMENTS = 'GET_COMPANY_DOCUMENTS';
export const UPLOADED_DOCUMENTS = 'UPLOADED_DOCUMENTS';

export const CLEAR_ALL = 'CLEAR_ALL';
export const CLEAR_TRAINING = 'CLEAR_TRAINING';

export const RECENT_CHATS_FOUND = 'RECENT_CHATS_FOUND';

export const STATS_LAST_SEVEN_DAY_COUNT = 'STATS_LAST_SEVEN_DAY_COUNT';
export const PRODUCTS_TALLY = 'PRODUCTS_TALLY';
export const CHAT_DATA = 'CHAT_DATA';
export const FULL_CHAT_DATA = 'FULL_CHAT_DATA';
export const PRODUCT_COUNT = 'PRODUCT_COUNT';
