import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Header from './header';
import Nav from './nav';
import { useGetUser } from '../../hooks/useGetUser';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [desktopWidth, setDesktopWidth] = useState(280);
  const { isLoggedIn, user, loading, userAdditionalInfo, additionalInfoLoading } = useGetUser();
  console.log('user 1', user);

  const handleClick = () => {
    if (desktopWidth === 280) {
      setDesktopWidth(0);
    } else {
      setDesktopWidth(280);
    }
  };

  return (
    <StyledRoot>
      <Header
        onOpenNav={() => setOpen(true)}
        desktopWidth={desktopWidth}
        setDesktopWidth={setDesktopWidth}
        handleClick={handleClick}
        loading={loading}
        user={user}
      />
      <Nav
        openNav={open}
        onCloseNav={() => setOpen(false)}
        desktopWidth={desktopWidth}
        setDesktopWidth={setDesktopWidth}
        handleClick={handleClick}
        loading={loading}
        user={user}
      />

      <Main style={{ backgroundColor: 'white' }}>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
