import { DELETE_PRODUCT, GET_PRODUCTS } from '../types';

const initialState = {
  productsList: [],
  productsLoading: true,
};

export default function Caller(state = initialState, action) {
  switch (action.type) {
    case DELETE_PRODUCT: {
      const updatedProducts = state.productsList.filter((product) => {
        return action.payload !== product.product_id;
      });
      return {
        ...state,
        productsList: updatedProducts,
        productsLoading: false,
      };
    }
    case GET_PRODUCTS:
      return {
        ...state,
        productsLoading: false,
        productsList: action.payload.products,
        productsTotal: action.payload.total,
      };
    default:
      return state;
  }
}
