import toast from 'react-hot-toast';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { MuiColorInput } from 'mui-color-input';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { newProductFormValidation } from '../../../../helpers/validation/newProductFormValidation';
import { editProduct } from '../../../../store/actions/products/editProduct';
import Label from '../../../../components/label/index';

export default function EditForm({ product }) {
  const [color, setColor] = useState('#ffffff');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [userName, setUsername] = useState('');
  const [dateCreated, setDateCreated] = useState('');
  const [productId, setProductId] = useState('');
  const [status, setStatus] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    if (product) {
      setName(product.product_name);
      setColor(product.product_color);
      setDescription(product.product_description);
      setType(product.product_type);
      setUsername(product.full_name);
      setDateCreated(product.date_created);
      setProductId(product.product_id);
      setStatus(product.product_status);
      setPhoneNumber(product.product_phone_number);
    }

    return () => {
      setName('');
      setColor('#ffffff');
      setDescription('');
      setType('');
      setUsername('');
      setDateCreated('');
      setProductId('');
      setStatus('');
      setPhoneNumber('');
    };
  }, [product]);

  const productTypes = [
    'Drug',
    'Medical Device',
    'Biologics',
    'Durable Medical Equipement (DME)',
    'OCT Supplements',
    'Other',
  ];

  const productStatus = [
    { label: 'Active', value: 1, color: 'info' },
    { label: 'Draft', value: 0, color: 'error' },
  ];

  const dispatch = useDispatch();

  const handleChangeColor = (color) => {
    setColor(color);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleClick = () => {
    const err = newProductFormValidation(name, description, color, type, phoneNumber);
    if (!err.message) {
      const productObj = {
        name,
        description,
        color,
        type,
        productId,
        status,
        phoneNumber,
      };
      dispatch(editProduct(productObj));
    } else {
      toast.error(err.message || 'Error, please check all your fields');
    }
  };

  return (
    <>
      <Card sx={{ backgroundColor: 'transparent' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Box sx={{ mt: 2, width: '100%', mb: 3 }}>
                <Typography className="regular-text" sx={{ mb: 1 }}>
                  Product Color
                </Typography>
                <MuiColorInput style={{ width: '100%' }} value={color} onChange={handleChangeColor} />
              </Box>
            </Grid>

            <Grid item md={6}>
              <Box sx={{ mt: 2 }}>
                <Typography className="regular-text" sx={{ mb: 1 }}>
                  Product Name
                </Typography>
                <TextField
                  value={name}
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>
            </Grid>

            <Grid item md={12}>
              <Box sx={{ mt: 3 }}>
                <Typography className="regular-text" sx={{ mb: 1 }}>
                  Product Description
                </Typography>
                <TextField
                  value={description}
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={3}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Box>
            </Grid>

            <Grid item md={12}>
              <Box sx={{ mt: 3 }}>
                <Typography className="regular-text" sx={{ mb: 1 }}>
                  Product Support Phone Number
                </Typography>
                <TextField
                  value={phoneNumber}
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Box>
            </Grid>

            <Grid item md={6}>
              <Box sx={{ mt: 3 }}>
                <Typography className="regular-text" sx={{ mb: 1 }}>
                  Product Type
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={type}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={handleTypeChange}
                  >
                    {productTypes ? (
                      productTypes.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item md={6}>
              <Box sx={{ mt: 3 }}>
                <Typography className="regular-text" sx={{ mb: 1 }}>
                  Product Status
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={status}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={handleStatusChange}
                  >
                    {productStatus ? (
                      productStatus.map((item) => (
                        <MenuItem key={item.label} value={item.value}>
                          <Label color={item.color}>{item.label}</Label>
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item md={6}>
              <Box sx={{ mt: 2 }}>
                <Typography className="regular-text" sx={{ mb: 1 }}>
                  Created By
                </Typography>
                <TextField disabled value={userName} fullWidth id="outlined-basic" variant="outlined" />
              </Box>
            </Grid>

            <Grid item md={6}>
              <Box sx={{ mt: 2 }}>
                <Typography className="regular-text" sx={{ mb: 1 }}>
                  Date Created
                </Typography>
                <TextField disabled value={dateCreated} fullWidth id="outlined-basic" variant="outlined" />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box className="flex justify-end">
        <Button
          sx={{ mt: 2, backgroundColor: '#212b36', color: 'white', '&:hover': { backgroundColor: 'black' } }}
          size="large"
          onClick={handleClick}
        >
          Save Changes
        </Button>
      </Box>
    </>
  );
}
