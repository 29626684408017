// component
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'dashboard',
    path: '/app',
    icon: <GridViewRoundedIcon />,
  },
  {
    title: 'Products',
    path: '/products',
    icon: <ViewListRoundedIcon style={{ color: '#637381' }} />,
    collapse: true,
    child: [
      {
        title: 'List',
        path: '/products',
        icon: <FiberManualRecordRoundedIcon style={{ fontSize: '10px' }} />,
      },
      {
        title: 'Create',
        path: '/create-product',
        icon: <FiberManualRecordRoundedIcon style={{ fontSize: '10px' }} />,
      },
    ],
  },
  {
    title: 'Canned Q/A',
    path: '/canned',
    icon: <QuestionAnswerIcon style={{ color: '#637381' }} />,
    collapse: true,
    child: [
      {
        title: 'List',
        path: '/canned',
        icon: <FiberManualRecordRoundedIcon style={{ fontSize: '10px' }} />,
      },
      {
        title: 'Create',
        path: '/create-canned-question',
        icon: <FiberManualRecordRoundedIcon style={{ fontSize: '10px' }} />,
      },
    ],
  },
  {
    title: 'AI',
    path: '/ai/products',
    icon: <AutoAwesomeIcon style={{ color: '#637381' }} />,
    collapse: true,
    child: [
      {
        title: 'Train AI',
        path: '/ai/products',
        icon: <FiberManualRecordRoundedIcon style={{ fontSize: '10px' }} />,
      },
      {
        title: 'Trained Documents',
        path: '/ai/trained-documents',
        icon: <FiberManualRecordRoundedIcon style={{ fontSize: '10px' }} />,
      },
    ],
  },
  {
    title: 'Chat',
    path: '/chat',
    icon: <GridViewRoundedIcon />,
  },
];

export default navConfig;
