import { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { AppWebsiteVisits } from '../../../../sections/@dashboard/app';
import { useGetLastSevenDayChatCount } from '../../../../hooks/stats/useGetLastSevenDayChatCount';

export default function LastSevenDayChatCount() {
  const { lastSevenDayChats, lastSevenDayChatsLoading } = useGetLastSevenDayChatCount();
  const [dates, setDates] = useState([]);
  const [tally, setTally] = useState([]);

  useEffect(() => {
    if (lastSevenDayChats && lastSevenDayChats.length >= 1) {
      const dataArrays = lastSevenDayChats.reduce(
        (acc, element) => {
          acc.data.push({ date: element.date, tally: element.tally });
          return acc;
        },
        { data: [] }
      );
      const sortedData = dataArrays.data.sort((a, b) => new Date(a.date) - new Date(b.date));
      const sortedDates = sortedData.map((entry) => entry.date);
      const sortedTally = sortedData.map((entry) => entry.tally);
      setDates(sortedDates);
      setTally(sortedTally);
    }
  }, [lastSevenDayChats]);

  if (lastSevenDayChatsLoading) {
    return (
      <Box>
        <AppWebsiteVisits
          title="Recent Chats"
          subheader="Last 6 day chat count"
          chartLabels={[]}
          chartData={[
            {
              name: 'Chats',
              type: 'area',
              fill: 'gradient',
              data: [],
            },
          ]}
        />
      </Box>
    );
  }

  if (!lastSevenDayChats || lastSevenDayChats.length < 1) {
    return (
      <Box>
        <Card sx={{ height: '52vh' }}>
          <CardContent className="flex justify-center align-center column" sx={{ height: '100%' }}>
            <Box className="flex justify-center align-center column">
              <img
                src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/aimedinfo/line-chart.svg"
                alt="line-chart"
                style={{ width: '50%' }}
              />
              <Typography>No data yet</Typography>
              <Typography sx={{ fontSize: '12px' }} className="text-center">
                Currently, there is no data available for this chart. Please wait for chats to occur before the data
                becomes visible.
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box>
      <AppWebsiteVisits
        title="Recent Chats"
        subheader="Last 6 day chat count"
        chartLabels={dates}
        chartData={[
          {
            name: 'Chats',
            type: 'area',
            fill: 'gradient',
            data: tally,
          },
        ]}
      />
    </Box>
  );
}
