import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui

import { Avatar, Box, Drawer, Link, Skeleton, Stack, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { Auth } from 'aws-amplify';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import NavSection from '../../../components/nav-section';
import Scrollbar from '../../../components/scrollbar';
//
import navConfig from './config';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav, desktopWidth, setDesktopWidth, handleClick, loading, user }) {
  const NAV_WIDTH = desktopWidth;
  const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
  }));

  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const navigate = useNavigate();

  const logout = async () => {
    try {
      await Auth.signOut();
      navigate('/login');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      {loading ? (
        <>
          <Box sx={{ mb: 5, mx: 2.5 }}>
            <Link underline="none">
              <StyledAccount>
                <Avatar src={account.photoURL} alt="photoURL" />

                <Box sx={{ ml: 2, width: '100%' }}>
                  <Skeleton variant="text" sx={{ fontSize: '1rem', width: '100%' }} />
                </Box>
              </StyledAccount>
            </Link>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ mb: 5, mx: 2.5 }}>
            <Link underline="none">
              <StyledAccount>
                <Avatar src={account.photoURL} alt="photoURL" />

                <Box sx={{ ml: 2 }}>
                  <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                    {user.attributes.email}
                  </Typography>

                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {account.role}
                  </Typography>
                </Box>
              </StyledAccount>
            </Link>
          </Box>
        </>
      )}

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          {/* <Box
            component="img"
            src="/assets/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          /> */}

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Logout
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Have a great day
            </Typography>
          </Box>

          <button
            onClick={logout}
            type="submit"
            className="w-full inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 hover:from-violet-600 hover:to-blue-600 border border-transparent text-white text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-4 dark:focus:ring-offset-gray-800"
          >
            Logout
          </button>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              // bgcolor: 'background.default',
              borderRightStyle: 'dashed',
              backgroundColor: 'white',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
