import { Container, Box, Typography } from '@mui/material';
import ChatTable from './helpers/ChatTable';

export default function Chat() {
  return (
    <Container maxWidth="xl">
      <Typography variant="h4" sx={{ mb: 5 }}>
        Chat Details
      </Typography>

      <Box sx={{ mt: 4 }}>
        <ChatTable />
      </Box>
    </Container>
  );
}
