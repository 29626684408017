import { Box, Button, Card, CardActionArea, CardContent, Grid, Menu, Typography } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useState } from 'react';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

const typesArray = [
  {
    id: 1,
    title: 'Patients',
    image: 'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/aimedinfo/patient.svg',
    dataType: 'application/pdf',
  },
  {
    id: 2,
    title: 'HCPs',
    image: 'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/aimedinfo/doctor.svg',
    dataType: 'image/png',
  },
];

export default function ChatByTypes({
  title,
  typeItemRemove,
  setTypeItemRemove,
  removeAll,
  typesFinal,
  setTypesFinal,
}) {
  const [types, setTypes] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (item) => {
    if (types && types.includes(item)) {
      setTypes(types.filter((user) => user !== item));
    } else {
      setTypes([...types, item]);
    }
  };

  const applySelect = () => {
    setAnchorEl(null);
    setTypesFinal(types);
  };

  useEffect(() => {
    if (removeAll) {
      setTypes([]);
    }
  }, [removeAll]);

  useEffect(() => {
    if (typeItemRemove) {
      if (types && types.includes(typeItemRemove)) {
        setTypes(types.filter((item) => item !== typeItemRemove));
      }
    }
    return () => {
      setTypeItemRemove({});
    };
  }, [typeItemRemove]);

  return (
    <Box>
      <Button sx={{ ml: 2 }} onClick={handleClick} endIcon={<KeyboardArrowDownIcon />}>
        {title || 'Chat By'}
      </Button>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ p: 2 }}>
          {typesArray && typesArray.length >= 1 ? (
            <>
              <Typography sx={{ fontWeight: 'bold' }}>Select All Chats From:</Typography>
              {typesArray.map((item, index) => (
                <Card key={index} sx={{ backgroundColor: types && types.includes(item) ? '#dddddd' : 'inherit' }}>
                  <CardActionArea onClick={() => handleSelect(item)}>
                    <Box sx={{ p: 2 }} className="flex align-center">
                      <img src={item.image} alt={item.title} width="32px" height="32px" />
                      <Typography sx={{ ml: 2 }}>{item.title}</Typography>
                    </Box>
                  </CardActionArea>
                </Card>
              ))}
            </>
          ) : (
            <></>
          )}
          <Box className="flex justify-end align-center" sx={{ mt: 2 }}>
            <Button variant="" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              onClick={applySelect}
              variant={types && types.length >= 1 ? 'contained' : 'outlined'}
              sx={{ ml: 1 }}
            >
              Apply Selected
            </Button>
          </Box>
        </Box>
      </StyledMenu>
    </Box>
  );
}
