import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCompanyProducts } from '../../../../store/actions/products/getAllCompanyProducts';
import Label from '../../../../components/label/Label';
import CustomLoader from '../../../../components/ui/CustomLoader';

export default function SelectProduct({ productId, setProductId }) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState('');

  const dispatch = useDispatch();
  const allCompanyProducts = useSelector((state) => state.companyProducts.allCompanyProducts);
  const allCompanyProductsLoading = useSelector((state) => state.companyProducts.allCompanyProductsLoading);

  React.useEffect(() => {
    dispatch(getAllCompanyProducts());
  }, []);

  React.useEffect(() => {
    if (Array.isArray(allCompanyProducts)) {
      setOptions(allCompanyProducts);
      const productFound = allCompanyProducts.find((option) => option.product_id === productId);
      setSelectedProduct(productFound || '');
    }
  }, [allCompanyProducts, productId]);

  return (
    <>
      <Typography className="regular-text" sx={{ mb: 1 }}>
        Product
      </Typography>
      <Autocomplete
        id="asynchronous-demo"
        fullWidth
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionLabel={(option) => option.product_name}
        options={options}
        value={selectedProduct || null} // Set the value based on selectedProduct
        onChange={(event, newValue) => {
          if (newValue) {
            setProductId(newValue.product_id);
          } else {
            setProductId(null);
          }
        }}
        loading={allCompanyProductsLoading}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <SquareRoundedIcon
              style={{
                fontSize: '30px',
                borderRadius: '3px',
                backgroundColor: option.product_color || 'white',
                color: option.product_color || 'white',
                marginRight: '8px',
              }}
            />
            <Typography> {option.product_name}</Typography>
            <Label sx={{ ml: 2 }} color={option.product_status === 1 ? 'info' : 'error'}>
              {option.product_status === 1 ? 'ACTIVE' : 'DRAFT'}
            </Label>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {allCompanyProductsLoading ? <CustomLoader /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
}
