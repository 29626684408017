import { toast } from 'react-hot-toast';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { CANNED_QUESTION_API } from '../../API';
import { DELETE_CANNED_QUESTION } from '../../types';
import { getCannedQuestions } from './getCannedQuestions';

export const deleteCanned = (cannedId) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;

  try {
    const res = await axios.delete(CANNED_QUESTION_API, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      },
      params: {
        email,
        cannedId,
      },
    });
    if (res && res.data && res.data.success === true) {
      toast.success('Question successfully deleted');
      dispatch({
        type: DELETE_CANNED_QUESTION,
        payload: res.data,
      });
      dispatch(getCannedQuestions(0, ''));
    } else {
      toast.error('Error deleting question! Please try again');
    }
  } catch (err) {
    toast.error('Error deleting question! Please try again');
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      console.log(err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};
