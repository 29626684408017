import toast from 'react-hot-toast';
import { Box, Button, Card, CardContent, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import SelectProduct from './SelectProduct';
import { newCannedQuestionFormValidation } from '../../../../helpers/validation/newCannedQuestionFormValidation';
import { createNewCannedQuestion } from '../../../../store/actions/canned/createNewCannedQuestion';
import RichTexEditor from '../../../../components/rich/RichTextEditor';

export default function CannedForm() {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [productId, setProductId] = useState('');
  const dispatch = useDispatch();

  const handleClick = () => {
    const err = newCannedQuestionFormValidation(question, answer, productId);
    if (!err.message) {
      const productObj = {
        question,
        answer,
        productId,
      };
      dispatch(createNewCannedQuestion(productObj));
      setQuestion('');
      setAnswer('');
      setProductId('');
    } else {
      toast.error(err.message || 'Error, please check all your fields');
    }
  };

  return (
    <>
      <Card sx={{ backgroundColor: 'transparent' }}>
        <CardContent>
          <Box sx={{ mt: 3 }}>
            <SelectProduct productId={productId} setProductId={setProductId} />
          </Box>
          <Box sx={{ mt: 3 }}>
            <Typography className="regular-text" sx={{ mb: 1 }}>
              Question
            </Typography>
            <TextField
              value={question}
              fullWidth
              id="outlined-basic"
              variant="outlined"
              multiline
              rows={5}
              onChange={(e) => setQuestion(e.target.value)}
            />
          </Box>
          <Box sx={{ mt: 3, mb: 5 }}>
            <Typography className="regular-text" sx={{ mb: 1 }}>
              Question Answer
            </Typography>
            <RichTexEditor answer={answer} setAnswer={setAnswer} />
          </Box>
          {/* <Box sx={{ mt: 8 }}>
            <Typography className="regular-text" sx={{ mb: 1 }}>
              Question Answer
            </Typography>
            <TextField
              value={answer}
              fullWidth
              variant="outlined"
              multiline
              rows={5}
              onChange={(e) => setAnswer(e.target.value)}
            />
          </Box> */}
        </CardContent>
      </Card>

      <Box className="flex justify-end mt-5">
        <button
          type="submit"
          onClick={handleClick}
          className="inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 hover:from-violet-600 hover:to-blue-600 border border-transparent text-white text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-4 dark:focus:ring-offset-gray-800"
        >
          Create Question
        </button>
      </Box>
    </>
  );
}
