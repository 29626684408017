const initialState = {
  userData: [],
  userDataLoading: true,
  companyUsers: [],
  companyUsersLoading: true,
};

export default function Caller(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
