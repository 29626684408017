export function formatTimestamp(inputTimestamp) {
  // Convert input timestamp to Date object
  const date = new Date(inputTimestamp);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  const formattedTimestamp = `${month} ${day}, ${year}`;
  return formattedTimestamp;
}
