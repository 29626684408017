import { CheckBox } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

export default function TableRowsLoader({ rowsNum, documentDeletionInProgress }) {
  const visibleRows = Array.from({ length: rowsNum }, (_, index) => index); // Generate an array of indices from 0 to rowsNum - 1

  return (
    <Box sx={{ width: '100%', mt: 4 }}>
      <Grid spacing={3} container className="flex justify-center align-center">
        <Grid item md={12} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <Box sx={{ p: 2, borderRadius: '15px' }} className="flex justify-between align-center">
              <Box sx={{ width: '30%', pr: 2 }}>
                <form noValidate>
                  <TextField
                    id="outlined-basic"
                    label="Search"
                    type="search"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton disabled>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    disabled
                  />
                </form>
              </Box>
              <Box className="flex justify-center align-center">
                <Button endIcon={<KeyboardArrowDownIcon />} disabled>
                  Products
                </Button>
                <Button sx={{ ml: 2 }} disabled endIcon={<KeyboardArrowDownIcon />}>
                  Select Date
                </Button>
                <Button sx={{ ml: 2 }} disabled endIcon={<KeyboardArrowDownIcon />}>
                  Types
                </Button>
              </Box>
            </Box>

            <TableContainer>
              {documentDeletionInProgress ? (
                <>
                  <Alert sx={{ backgroundColor: '#fff0b5' }} severity="warning">
                    Document deletion in progress. Please wait
                  </Alert>
                </>
              ) : (
                <></>
              )}
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <TableBody>
                  {visibleRows.map((index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index} sx={{ cursor: 'pointer' }}>
                        <TableCell padding="checkbox">
                          <Checkbox disabled />
                        </TableCell>
                        <TableCell sx={{ p: 2 }} component="th" id={labelId} scope="row" padding="none">
                          <Skeleton animation="wave" variant="text" />
                        </TableCell>
                        <TableCell align="right">
                          <Skeleton animation="wave" variant="text" />
                        </TableCell>
                        <TableCell align="right">
                          <Skeleton animation="wave" variant="text" />
                        </TableCell>
                        <TableCell align="right">
                          <Skeleton animation="wave" variant="text" />
                        </TableCell>
                        <TableCell align="right">
                          <Skeleton animation="wave" variant="text" />
                        </TableCell>
                        <TableCell align="right">
                          <Skeleton animation="wave" variant="text" />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
