import { GET_PRODUCT, DELETE_PRODUCT } from '../types';

const initialState = {
  product: {},
  productLoading: true,
};

export default function Caller(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT:
      return {
        ...state,
        productLoading: false,
        product: action.payload,
      };
    default:
      return state;
  }
}
