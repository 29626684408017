import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import toast from 'react-hot-toast';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import SendIcon from '@mui/icons-material/Send';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate } from 'react-router-dom';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card, Chip, Grid, InputAdornment, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { formatString } from '../../../../helpers/formatString';
import Label from '../../../../components/label';
import { getCannedQuestions } from '../../../../store/actions/canned/getCannedQuestions';
import { deleteCanned } from '../../../../store/actions/canned/deleteCanned';
import CustomLoader from '../../../../components/ui/CustomLoader';
import ProductsSelectButton from '../../ai/documents/helpers/ProductsSelectButton';
import CannedFilters from './helpers/CannedFilters';

const headCells = [
  {
    id: 'product',
    numeric: false,
    disablePadding: false,
    label: 'Product',
  },
  {
    id: 'question',
    numeric: false,
    disablePadding: false,
    label: 'Question',
  },
  {
    id: 'publish',
    numeric: true,
    disablePadding: false,
    label: 'Publish',
  },
  {
    id: 'created_on',
    numeric: true,
    disablePadding: false,
    label: 'Created On',
  },
  {
    id: '',
    numeric: true,
    disablePadding: false,
    label: '',
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const {
    selectedUsersFinal,
    setSelectedUsersFinal,
    removeAll,
    setRemoveAll,
    setUsersRemove,
    usersRemove,
    numSelected,
    setSearch,
    search,
    setSearchValue,
    searchValue,
  } = props;

  const handleSearch = () => {
    setSearchValue(search);
  };

  const handleRemoveSearch = () => {
    setSearchValue('');
    setSearch('');
  };

  return (
    <Toolbar
      sx={{
        p: 2.5,
      }}
    >
      <Grid container spacing={3} className="flex justify-between align-center">
        <Grid item md={4} sx={{ width: '100%' }}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRoundedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment>
                  <IconButton onClick={handleSearch}>
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            id="outlined-basic"
            placeholder="Search"
            type="search"
            value={search}
            variant="outlined"
            fullWidth
            autoComplete="off"
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setSearch(e.target.value);
                handleSearch();
              }
            }}
          />
        </Grid>
        <Grid item md={4} sx={{ width: '100%' }} className="flex justify-end">
          <ProductsSelectButton
            usersRemove={usersRemove}
            setUsersRemove={setUsersRemove}
            setSelectedUsersFinal={setSelectedUsersFinal}
            selectedUsersFinal={selectedUsersFinal}
            removeAll={removeAll}
            setRemoveAll={setRemoveAll}
          />
        </Grid>
        {searchValue && searchValue.length >= 1 ? (
          <>
            <Grid item md={12} sx={{ width: '100%' }}>
              <Box className="flex" sx={{ pb: 2, flexWrap: 'wrap' }}>
                <Box>
                  {searchValue && searchValue.length >= 1 ? (
                    <>
                      <Card className="flex justify-center align-center" sx={{ border: 'dashed 1px', pr: 0.5 }}>
                        <Typography sx={{ pl: 1, pr: 1, fontSize: '14px', fontWeight: 'bold' }}>Search: </Typography>

                        <Box sx={{ p: 0.3, pt: 1, pb: 1 }}>
                          <Chip variant="outlined" onDelete={handleRemoveSearch} label={searchValue || ''} />
                        </Box>
                      </Card>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </Toolbar>
  );
}

export default function CannedTable() {
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [visibleRows, setVisibleRows] = React.useState([]);
  const [deleteRow, setDeleteRow] = React.useState('');
  const [filters, setFilters] = React.useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cannedQuestions = useSelector((state) => state.canned.cannedQuestions);
  const cannedQuestionsLoading = useSelector((state) => state.canned.cannedQuestionsLoading);
  const cannedQuestionsLength = useSelector((state) => state.canned.cannedQuestionsTotal);

  React.useEffect(() => {
    console.log('filters', filters);
    dispatch(getCannedQuestions(page, filters));
  }, [page, filters, dispatch]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    setVisibleRows(cannedQuestions);
  }, [cannedQuestions]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterDispatch = (filterPayload) => {
    setFilters(filterPayload);
    dispatch(getCannedQuestions(page, filterPayload));
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleDelete = (question) => {
    // perform delte logic
    setDeleteRow(question);
    handleClickOpen();
  };

  const handleDeleteProduct = () => {
    if (deleteRow && deleteRow.canned_id) {
      dispatch(deleteCanned(deleteRow.canned_id));
      handleClose();
    } else {
      toast.error('Unable to delete row. Please try again');
    }
  };

  if (cannedQuestionsLoading) {
    return <CustomLoader />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
        }}
      >
        {/* <EnhancedTableToolbar
          searchValue={searchValue}
          setSearch={setSearch}
          search={search}
          setSearchValue={setSearchValue}
          usersRemove={usersRemove}
          setUsersRemove={setUsersRemove}
          selectedUsersFinal={selectedUsersFinal}
          setSelectedUsersFinal={setSelectedUsersFinal}
          removeAll={removeAll}
          setRemoveAll={setRemoveAll}
        /> */}
        <CannedFilters page={page} onFilterApply={handleFilterDispatch} />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
            <EnhancedTableHead rowCount={cannedQuestions && cannedQuestions.length} />
            <TableBody>
              {visibleRows &&
                visibleRows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      onClick={(e) => navigate(`/canned/edit/${row.canned_id}`)}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      sx={{ cursor: 'pointer' }}
                    >
                      {/* <TableCell
                        style={{
                          maxWidth: '250px',
                        }}
                      >
                        <Typography className="basic-text">{formatString(row.answer || '', 80)}</Typography>
                      </TableCell> */}
                      <TableCell
                        style={{
                          maxWidth: '220px',
                        }}
                      >
                        <Box className="flex align-center">
                          <SquareRoundedIcon
                            style={{
                              fontSize: '30px',
                              borderRadius: '3px',
                              backgroundColor: row.product_color || 'white',
                              color: row.product_color || 'white',
                            }}
                          />
                          <Box sx={{ ml: 1 }}>
                            <Typography sx={{ fontSize: '14px' }}>
                              {formatString(row.product_name || '', 45)}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell
                        style={{
                          maxWidth: '240px',
                        }}
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        <Box className="flex align-center">
                          <HelpCenterIcon
                            style={{
                              fontSize: '40px',
                              borderRadius: '3px',
                              backgroundColor: 'white',
                              color: 'black',
                            }}
                          />
                          <Box sx={{ ml: 2 }}>
                            <Typography className="table-title-value-text">
                              {formatString(row.question || '', 40)}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>

                      <TableCell align="right">
                        <Label color={row.question_status === 1 ? 'info' : 'error'}>
                          {row.question_status === 1 ? 'ACTIVE' : 'DRAFT'}
                        </Label>
                      </TableCell>

                      <TableCell align="right">
                        <Box className="flex justify-end align-center">
                          <CalendarMonthIcon style={{ color: 'gray' }} />
                          <Typography className="basic-text" sx={{ ml: 1 }}>
                            {row.date_created}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            handleDelete(row);
                          }}
                          aria-label="more"
                        >
                          <DeleteIcon style={{ color: '#ff5630' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[8]}
          component="div"
          count={cannedQuestionsLength || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete?'}</DialogTitle>
        <DialogContent>
          <Typography id="alert-dialog-description">
            Are you sure you want to proceed with deleting this question?
          </Typography>
          <DialogContentText sx={{ mt: 2 }}>
            Please note that this action cannot be undone once the deletion is confirmed.
          </DialogContentText>
          <DialogContentText sx={{ mt: 2 }}>
            <span style={{ textDecoration: 'bold', fontWeight: 600 }}>Question - </span>
            {deleteRow && deleteRow.id ? deleteRow.question : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteProduct} variant="outlined">
            Delete
          </Button>
          <Button onClick={handleClose} autoFocus variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

// import * as React from 'react';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { alpha } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Table from '@mui/material/Table';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TablePagination from '@mui/material/TablePagination';
// import TableRow from '@mui/material/TableRow';
// import TableSortLabel from '@mui/material/TableSortLabel';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import Paper from '@mui/material/Paper';
// import Checkbox from '@mui/material/Checkbox';
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';
// import { useNavigate } from 'react-router-dom';
// import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
// import { useDispatch, useSelector } from 'react-redux';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import { visuallyHidden } from '@mui/utils';
// import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
// import { formatString } from '../../../../helpers/formatString';
// import FileTypeImage3D from '../../../../helpers/FileTypeImage3D';
// import { getCannedQuestions } from '../../../../store/actions/canned/getCannedQuestions';
// import { deleteCanned } from '../../../../store/actions/canned/deleteCanned';
// // import DocumentFilters from './DocumentFilters';
// // import DownloadFile from './DownloadFile';
// // import DeleteDocument from './DeleteDocument';
// // import { deleteDocument } from '../../../../../store/actions/documents/deleteDocuments';
// import CannedTableRowsLoader from './helpers/CannedTableRowsLoader';

// const headCells = [
//   {
//     id: 'question',
//     numeric: false,
//     disablePadding: false,
//     label: 'Questions',
//   },
//   {
//     id: 'answers',
//     numeric: false,
//     disablePadding: false,
//     label: 'Answers',
//   },
//   {
//     id: 'product',
//     numeric: false,
//     disablePadding: false,
//     label: 'Product',
//   },
//   {
//     id: 'publish',
//     numeric: true,
//     disablePadding: false,
//     label: 'Publish',
//   },
//   {
//     id: 'created_on',
//     numeric: true,
//     disablePadding: false,
//     label: 'Created On',
//   },
//   {
//     id: '',
//     numeric: true,
//     disablePadding: false,
//     label: '',
//   },
// ];

// function EnhancedTableHead(props) {
//   const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
//   const createSortHandler = (property) => (event) => {
//     onRequestSort(event, property);
//   };

//   return (
//     <TableHead>
//       <TableRow>
//         <TableCell padding="checkbox">
//           {/* <Checkbox
//             color="primary"
//             indeterminate={numSelected > 0 && numSelected < rowCount}
//             checked={rowCount > 0 && numSelected === rowCount}
//             onChange={onSelectAllClick}
//             inputProps={{
//               'aria-label': 'select all desserts',
//             }}
//           /> */}
//         </TableCell>
//         {headCells.map((headCell) => (
//           <TableCell
//             key={headCell.id}
//             align={headCell.numeric ? 'left' : 'right'}
//             padding={headCell.disablePadding ? 'none' : 'normal'}
//             sortDirection={orderBy === headCell.id ? order : false}
//           >
//             <TableSortLabel
//               active={orderBy === headCell.id}
//               direction={orderBy === headCell.id ? order : 'asc'}
//               onClick={createSortHandler(headCell.id)}
//             >
//               {headCell.label}
//               {orderBy === headCell.id ? (
//                 <Box component="span" sx={visuallyHidden}>
//                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
//                 </Box>
//               ) : null}
//             </TableSortLabel>
//           </TableCell>
//         ))}
//       </TableRow>
//     </TableHead>
//   );
// }

// function EnhancedTableToolbar(props) {
//   const { numSelected, selected, setSelected, setSelectedObjects } = props;
//   const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
//   const dispatch = useDispatch();

//   const handleDeleteDialogClickOpen = () => {
//     setOpenDeleteDialog(true);
//   };

//   const handleDeleteDialogClose = () => {
//     setOpenDeleteDialog(false);
//   };

//   const deleteAll = (selected) => {
//     const documents = selected.map((item) => ({
//       id: item.document_id,
//       name: item.document_name,
//       productId: item.product_id,
//     }));
//     setSelectedObjects([]);
//     setSelected([]);
//     setOpenDeleteDialog(false);
//     // dispatch(deleteDocument(documents));
//   };

//   return (
//     <Toolbar
//       className="flex justify-between"
//       sx={{
//         pl: { sm: 2 },
//         pr: { xs: 1, sm: 1 },
//         ...(numSelected > 0 && {
//           bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
//         }),
//       }}
//     >
//       {numSelected > 0 ? (
//         <Typography color="inherit" variant="subtitle1" component="div">
//           {numSelected} selected
//         </Typography>
//       ) : (
//         <>
//           <Typography>{''}</Typography>
//         </>
//       )}

//       {numSelected > 0 ? (
//         <>
//           <Tooltip title="Delete">
//             <>
//               <IconButton onClick={handleDeleteDialogClickOpen}>
//                 <DeleteIcon />
//               </IconButton>
//             </>
//           </Tooltip>
//           <Dialog
//             open={openDeleteDialog}
//             onClose={handleDeleteDialogClose}
//             aria-labelledby="alert-dialog-title"
//             aria-describedby="alert-dialog-description"
//           >
//             <DialogTitle id="alert-dialog-title">
//               {'Are you sure you want to delete the selected documents below?'}
//             </DialogTitle>
//             <DialogContent>
//               <DialogContentText id="alert-dialog-description">
//                 {selected &&
//                   selected.length >= 1 &&
//                   selected.map((item, index) => (
//                     <Typography key={index} sx={{ fontWeight: 'bold' }}>
//                       {item.document_name}
//                     </Typography>
//                   ))}
//                 <Typography sx={{ mt: 2 }}>
//                   Confirming the deletion of this document will also exclude it from the AI's training dataset. Are you
//                   certain you want to proceed with deleting this document?
//                 </Typography>
//               </DialogContentText>
//             </DialogContent>
//             <DialogActions>
//               <Button
//                 startIcon={<DeleteIcon />}
//                 sx={{ backgroundColor: '#ff5630', color: 'white', '&:hover': { backgroundColor: '#ff4419' } }}
//                 onClick={() => deleteAll(selected)}
//               >
//                 Agree & Delete Documents
//               </Button>
//               <Button onClick={handleDeleteDialogClose} variant="outlined">
//                 Disagree
//               </Button>
//             </DialogActions>
//           </Dialog>
//         </>
//       ) : (
//         <Tooltip title="Filter list">
//           <IconButton>
//             <FilterListIcon />
//           </IconButton>
//         </Tooltip>
//       )}
//     </Toolbar>
//   );
// }

// export default function CannedTable() {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [order, setOrder] = React.useState('asc');
//   const [orderBy, setOrderBy] = React.useState('calories');
//   const [selected, setSelected] = React.useState([]);
//   const [page, setPage] = React.useState(0);
//   const [dense, setDense] = React.useState(false);
//   const [rowsPerPage, setRowsPerPage] = React.useState(8);
//   const [documents, setDocuments] = React.useState([]);
//   const [filters, setFilters] = React.useState({});
//   const [selectedObjects, setSelectedObjects] = React.useState([]); // Store selected document objects

//   const documentDeletionInProgress = useSelector((state) => state.documents.documentDeletionInProgress);

//   const cannedQuestions = useSelector((state) => state.canned.cannedQuestions);
//   const cannedQuestionsLoading = useSelector((state) => state.canned.cannedQuestionsLoading);
//   const cannedQuestionsLength = useSelector((state) => state.canned.cannedQuestionsTotal);

//   React.useEffect(() => {
//     dispatch(getCannedQuestions(page, ''));
//   }, [page]);

//   React.useEffect(() => {
//     setDocuments(cannedQuestions);
//   }, [cannedQuestions]);

//   const handleRequestSort = (event, property) => {
//     const isAsc = orderBy === property && order === 'asc';
//     setOrder(isAsc ? 'desc' : 'asc');
//     setOrderBy(property);
//   };

//   const handleSelectAllClick = (event) => {
//     if (event.target.checked) {
//       const newSelected = documents && documents.map((n) => n.name);
//       setSelected(newSelected);
//       return;
//     }
//     setSelected([]);
//   };

//   const handleClick = (event, document) => {
//     event.stopPropagation();
//     event.preventDefault();
//     const selectedIndex = selected.indexOf(document.document_name);
//     const newSelected = [...selected];
//     let newSelectedObjects = [...selectedObjects];

//     if (selectedIndex === -1) {
//       newSelected.push(document.document_name);
//       newSelectedObjects.push(document);
//     } else {
//       newSelected.splice(selectedIndex, 1);
//       newSelectedObjects = newSelectedObjects.filter((obj) => obj.id !== document.id);
//     }

//     setSelected(newSelected);
//     setSelectedObjects(newSelectedObjects);
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleChangeDense = (event) => {
//     setDense(event.target.checked);
//   };

//   const handleFilterDispatch = (filterPayload) => {
//     setFilters(filterPayload);
//     dispatch(getCannedQuestions(page, filterPayload));
//   };

//   const isSelected = (name) => selected.indexOf(name) !== -1;

//   const visibleRows = React.useMemo(() => {
//     if (!documents) {
//       return [];
//     }
//     return documents;
//   }, [documents, order, orderBy, page, rowsPerPage]);

//   if (cannedQuestionsLoading || documentDeletionInProgress) {
//     return <CannedTableRowsLoader rowsNum={8} documentDeletionInProgress={documentDeletionInProgress} />;
//   }

//   return (
//     <Box sx={{ width: '100%', mt: 4, pb: 10 }}>
//       <Grid spacing={3} container className="flex justify-center align-center">
//         <Grid item md={12} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
//           <Paper sx={{ width: '100%', mb: 2, mt: 4 }}>
//             {/* <DocumentFilters page={page} onFilterApply={handleFilterDispatch} /> */}
//             {selected && selected.length >= 1 ? (
//               <>
//                 <EnhancedTableToolbar
//                   selected={selectedObjects}
//                   numSelected={(selected && selected.length) || 0}
//                   setSelected={setSelected}
//                   setSelectedObjects={setSelectedObjects}
//                 />
//               </>
//             ) : (
//               <></>
//             )}
//             <TableContainer>
//               <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
//                 <EnhancedTableHead
//                   numSelected={(selected && selected.length) || 0}
//                   order={order}
//                   orderBy={orderBy}
//                   onSelectAllClick={handleSelectAllClick}
//                   onRequestSort={handleRequestSort}
//                   rowCount={(documents && documents.length) || 0}
//                 />
//                 <TableBody>
//                   {visibleRows &&
//                     visibleRows.map((row, index) => {
//                       const isItemSelected = isSelected(row.document_name);
//                       const labelId = `enhanced-table-checkbox-${index}`;

//                       return (
//                         <TableRow
//                           hover
//                           role="checkbox"
//                           aria-checked={isItemSelected}
//                           tabIndex={-1}
//                           key={index}
//                           selected={isItemSelected}
//                           sx={{ cursor: 'pointer' }}
//                           onClick={(e) => navigate(`/canned/edit/${row.canned_id}`)}
//                         >
//                           <TableCell padding="checkbox">
//                             {/* <Checkbox
//                               color="primary"
//                               checked={isItemSelected}
//                               inputProps={{
//                                 'aria-labelledby': labelId,
//                               }}
//                               onClick={(event) => handleClick(event, row)}
//                             /> */}
//                           </TableCell>
//                           <TableCell sx={{ p: 2 }} component="th" id={labelId} scope="row" padding="none">
//                             <Box className="flex align-center">
//                               {/* <FileTypeImage3D name={row.document_name} customWidth="30%" /> */}
//                               <Box sx={{ pl: 1.4 }}>{formatString(row.question, 20)}</Box>
//                             </Box>
//                           </TableCell>
//                           <TableCell
//                             style={{
//                               maxWidth: '220px',
//                             }}
//                           >
//                             <Box className="flex align-center">
//                               <SquareRoundedIcon
//                                 style={{
//                                   fontSize: '30px',
//                                   borderRadius: '3px',
//                                   backgroundColor: row.product_color || 'white',
//                                   color: row.product_color || 'white',
//                                 }}
//                               />
//                               <Box sx={{ ml: 1 }}>
//                                 <Typography sx={{ fontSize: '14px' }}>
//                                   {formatString(row.product_name || '', 45)}
//                                 </Typography>
//                               </Box>
//                             </Box>
//                           </TableCell>
//                           <TableCell align="right">
//                             <Typography sx={{ fontSize: '14px' }}>{formatString(row.full_name, 15)}</Typography>
//                             {formatString(row.email, 24)}
//                           </TableCell>
//                           <TableCell align="right">
//                             <Typography sx={{ fontSize: '14px' }}>{row.document_size}</Typography>
//                           </TableCell>
//                           <TableCell align="right">
//                             <Typography sx={{ fontSize: '14px' }}>{row.date_created}</Typography>
//                           </TableCell>
//                           {/* <TableCell align="right">
//                             <DownloadFile file={row} />
//                             <DeleteDocument file={row} />
//                           </TableCell> */}
//                         </TableRow>
//                       );
//                     })}
//                 </TableBody>
//               </Table>
//             </TableContainer>
//             <TablePagination
//               rowsPerPageOptions={[8]}
//               component="div"
//               count={cannedQuestionsLength || 0}
//               rowsPerPage={rowsPerPage}
//               page={page}
//               onPageChange={handleChangePage}
//               onRowsPerPageChange={handleChangeRowsPerPage}
//             />
//           </Paper>
//           <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
//         </Grid>
//       </Grid>
//     </Box>
//   );
// }
