import axios from 'axios';
import toast from 'react-hot-toast';
import { Auth } from 'aws-amplify';
import { CANNED_QUESTION_API } from '../../API';

export const editCannedQuestion = (cannedObj) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;
  try {
    axios
      .put(
        CANNED_QUESTION_API,
        {
          email,
          cannedObj: JSON.stringify(cannedObj),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          },
        }
      )
      .then((res) => {
        console.log('res', res.data);
        if (res && res.data) {
          toast.success('Question Successfully Saved');
        } else {
          toast.error('Unable to save question! Please try again');
        }
      })
      .catch((error) => {
        console.log('err saving product', error);
        toast.error('Unable to save question! Please try again');
      });
  } catch (err) {
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log('error', err.response.data);
      console.log('error', err.response.status);
    } else if (err.request) {
      console.log('error', err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};
