import { GET_ALL_COMPANY_PRODUCT } from '../types';

const initialState = {
  allCompanyProducts: {},
  allCompanyProductsLoading: true,
};

export default function Caller(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_COMPANY_PRODUCT:
      return {
        allCompanyProducts: action.payload.products,
        allCompanyProductsLoading: false,
      };
    default:
      return state;
  }
}
