import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';

export default function RichTextEditor({ answer, setAnswer }) {
  const handleChange = (newValue) => {
    setAnswer(DOMPurify.sanitize(newValue));
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['image', 'video'], // Add image and video modules
    ],
  };

  return (
    <ReactQuill
      theme="snow"
      style={{ height: '150px', fontSize: '15px', fontFamily: 'Public Sans' }}
      value={answer}
      onChange={handleChange}
      modules={modules}
    />
  );
}
