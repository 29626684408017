import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { toast } from 'react-hot-toast';
import { processTextFile } from '../../../../../helpers/chunking/processTextFile';
import { processPdfFile } from '../../../../../helpers/chunking/processPdfFile';
import { processImageFile } from '../../../../../helpers/chunking/processImageFile';
import { randomizeFileName } from '../../../../../helpers/randomizeFileName';
import { processExcelFile } from '../../../../../helpers/chunking/processExcelFile';

const FileSelectionLogic = ({
  chunkedOutputs,
  setSelectedFile,
  setChunkingInProgress,
  onSelect,
  setChunkedOutputs,
  handleDragOver,
  trainingInProgress,
}) => {
  const maxSizeInBytes = 35 * 1024 * 1024; // 35MB in bytes

  const handleFileSelect = async (event) => {
    setChunkingInProgress(true);
    if (event.target.files && event.target.files.length > 3) {
      toast.error('Please upload up to 3 files at a time');
      const files = Array.from(event.target.files).slice(0, 3);
      const validFiles = files.filter((file) => file.size <= maxSizeInBytes);
      const validFilesWithNewNames = randomizeFileName(validFiles);
      onSelect(validFilesWithNewNames);
    } else {
      const files = Array.from(event.target.files).slice(0, 3);
      const validFiles = files.filter((file) => file.size <= maxSizeInBytes);
      const validFilesWithNewNames = randomizeFileName(validFiles);
      onSelect(validFilesWithNewNames);

      // Limit chunkedOutputs length to 3
      const updatedChunkedOutputs = chunkedOutputs.slice(-2);
      setChunkedOutputs(updatedChunkedOutputs);

      // Show toast if more than 3 files are selected
      if (chunkedOutputs.length >= 3) {
        toast.error('To avoid overloading please keep your file uploads to (3)');
      }

      const inValidFiles = files.filter((file) => file.size >= maxSizeInBytes);
      if (inValidFiles && inValidFiles.length >= 1) {
        inValidFiles.forEach((file) => {
          const fileName = file.name;
          toast.error(`${fileName} size is over 35MB. Please compress your file or divide it to multiple files`);
        });
      }

      if (validFilesWithNewNames && validFilesWithNewNames.length >= 1) {
        const promises = validFilesWithNewNames.map(async (file) => {
          if (file.name.endsWith('.txt')) {
            const textFileOutput = await processTextFile(file, 1000);
            console.log('textFileOutput', textFileOutput);
            return textFileOutput;
          }
          if (file.name.endsWith('.pdf')) {
            const pdfFileOutput = await processPdfFile(file, 1000);
            return pdfFileOutput;
          }
          if (file.name.endsWith('.docx') || file.name.endsWith('.doc')) {
            console.log('Docx File');
            return null;
          }
          if (file.name.endsWith('.png') || file.name.endsWith('.jpg')) {
            const imgFileOutput = await processImageFile(file, 1000);
            return imgFileOutput;
          }

          if (file.name.endsWith('.csv') || file.name.endsWith('.xls')) {
            const excelFileOutput = await processExcelFile(file, 1000);
            console.log('excel file output', excelFileOutput);
            return excelFileOutput;
          }
          toast.error('Unsupported file type uploaded.');
          return null;
        });

        const outputs = await Promise.all(promises);
        setChunkedOutputs((prevOutputs) => {
          const filteredOutputs = outputs.filter((output) => output !== null && output.success === true);

          // Remove duplicates by using Set data structure
          const uniqueFilteredOutputs = [...new Set(filteredOutputs)];

          const combinedOutputs = [...prevOutputs, ...uniqueFilteredOutputs];

          // Ensure unique values and limit to the maximum allowed
          const newOutputs = [...new Set(combinedOutputs)].slice(-3);

          return newOutputs;
        });
      }
    }
    setChunkingInProgress(false);
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setChunkingInProgress(true);

    if (event.target.files && event.target.files.length > 3) {
      toast.error('Please upload up to 3 files at a time');
      const files = Array.from(event.dataTransfer.files).slice(0, 3);
      const validFiles = files.filter((file) => file.size <= maxSizeInBytes);
      const validFilesWithNewNames = randomizeFileName(validFiles);
      onSelect(validFilesWithNewNames);
    } else {
      const files = Array.from(event.dataTransfer.files).slice(0, 3);
      const validFiles = files.filter((file) => file.size <= maxSizeInBytes);
      const validFilesWithNewNames = randomizeFileName(validFiles);
      onSelect(validFilesWithNewNames);

      // Limit chunkedOutputs length to 3
      const updatedChunkedOutputs = chunkedOutputs.slice(-2);
      setChunkedOutputs(updatedChunkedOutputs);

      // Show toast if more than 3 files are selected
      if (chunkedOutputs.length >= 3) {
        toast.error('To avoid overloading please keep your file uploads to (3)');
      }

      const inValidFiles = files.filter((file) => file.size >= maxSizeInBytes);
      if (inValidFiles && inValidFiles.length >= 1) {
        inValidFiles.forEach((file) => {
          const fileName = file.name;
          toast.error(`${fileName} size is over 20MB. Please compress your file or divide it to multiple files`);
        });
      }

      if (validFilesWithNewNames && validFilesWithNewNames.length >= 1) {
        const promises = validFilesWithNewNames.map(async (file) => {
          if (file.name.endsWith('.txt')) {
            const textFileOutput = await processTextFile(file);
            console.log('textFileOutput', textFileOutput);
            return textFileOutput;
          }
          if (file.name.endsWith('.pdf')) {
            const pdfFileOutput = await processPdfFile(file);
            return pdfFileOutput;
          }
          if (file.name.endsWith('.docx') || file.name.endsWith('.doc')) {
            console.log('Docx File');
            return null;
          }
          if (file.name.endsWith('.png') || file.name.endsWith('.jpg')) {
            const imgFileOutput = await processImageFile(file);
            return imgFileOutput;
          }
          toast.error('Unsupported file type uploaded.');
          return null;
        });

        const outputs = await Promise.all(promises);
        setChunkedOutputs((prevOutputs) => {
          const filteredOutputs = outputs.filter((output) => output !== null && output.success === true);

          // Remove duplicates by using Set data structure
          const uniqueFilteredOutputs = [...new Set(filteredOutputs)];

          const combinedOutputs = [...prevOutputs, ...uniqueFilteredOutputs];

          // Ensure unique values and limit to the maximum allowed
          const newOutputs = [...new Set(combinedOutputs)].slice(-3);

          return newOutputs;
        });
      }
    }
    setChunkingInProgress(false);
  };

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <label htmlFor="raised-button-file">
          <input
            accept=".pdf, .txt, .docx, .png, .jpg, .csv, .xls"
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={handleFileSelect}
            disabled={trainingInProgress}
          />
          <Button
            variant="raised"
            component="span"
            onDrop={handleDrop}
            className="flex justify-center align-center"
            onDragOver={handleDragOver}
            disabled={trainingInProgress}
            sx={{
              width: '100%',
              backgroundColor: '#f7f7f7',
              p: 4,
              border: 'dashed 2.3px black',
              borderRadius: '12px',
            }}
          >
            <Box className="flex column justify-center align-center">
              <img
                src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/file-upload-v2.svg"
                alt="file-upload-img"
              />
              <Typography sx={{ mt: 2 }} variant="h6">
                Click Here or Drag & Drop a File Here
              </Typography>
              <Typography sx={{ mt: 1, color: 'gray' }}>
                Uploaded documents are private and can only be seen by you
              </Typography>
              <Box
                sx={{
                  mt: 1.5,
                  pl: 0.8,
                  pr: 0.8,
                  pt: 0.4,
                  pb: 0.4,
                  borderRadius: '6px',
                  alignItems: 'center',
                  border: 'solid 1px black',
                }}
              >
                Upload File
              </Box>
            </Box>
          </Button>
        </label>
      </Box>
      <Typography sx={{ color: 'gray', mt: 0.5 }} className="flex justify-end">
        Miximum file size is 35MB
      </Typography>
    </>
  );
};

export default FileSelectionLogic;
