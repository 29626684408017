// @mui
import { Card, CardContent, CardHeader, Container, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components

// sections
import { useGetChats } from '../../../hooks/stats/useGetChats';
import { useGetProductsCount } from '../../../hooks/stats/useGetProductsCount';
import CardSummaryData from './helpers/CardSummaryData';
import LastSevenDayChatCount from './helpers/LastSevenDayChatCount';
import ProductTally from './helpers/ProductTally';
import RecentChats from './helpers/RecentChats';
import RecentFiveProducts from './helpers/RecentFiveProducts';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const { chats, chatsLoading, patientChats, hcpChats } = useGetChats();
  const { productsCount, productsCountLoading, lastFiveProducts } = useGetProductsCount();

  return (
    <>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                title={'Welcome to AI Medinfo Pharma Admin Portal 👋'}
                subheader={
                  'Your all in one chatbot, streamlining and automating your medical information center for seamless assistance.                '
                }
              />
              <CardContent>{/* <Typography>Welcome to AI Medinfo Pharma Admin Portal</Typography> */}</CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <CardSummaryData
              loading={chatsLoading}
              title="Total Chats"
              total={(chats && chats.length) || 0}
              color="success"
              icon={
                <img
                  alt="chat-icon"
                  src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/aimedinfo/chat-bubbles.svg"
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardSummaryData
              loading={chatsLoading}
              title="Patient Chats"
              total={patientChats || 0}
              color="success"
              icon={
                <img
                  alt="patient icon"
                  src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/aimedinfo/patient-icon-circle.svg"
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardSummaryData
              loading={chatsLoading}
              title="HCP Chats"
              total={hcpChats || 0}
              color="success"
              icon={
                <img
                  alt="hcp icon"
                  src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/aimedinfo/hcp-icon-circle.svg"
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <CardSummaryData
              loading={productsCountLoading}
              title="Product Count"
              total={productsCount || 0}
              color="success"
              icon={
                <img
                  alt="products icon"
                  src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/aimedinfo/product-icon-circle.svg"
                />
              }
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <LastSevenDayChatCount />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <ProductTally type={'some'} />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <ProductTally type={'full'} />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <RecentFiveProducts lastFiveProducts={lastFiveProducts} productsCountLoading={productsCountLoading} />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <RecentChats />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
