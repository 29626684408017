import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChats } from '../../store/actions/stats/getChats';

export function useGetChats() {
  const patientChats = useSelector((state) => state.stats.patientChats);
  const hcpChats = useSelector((state) => state.stats.hcpChats);
  const chats = useSelector((state) => state.stats.chats);
  const chatsLoading = useSelector((state) => state.stats.chatsLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    async function companyUsers() {
      try {
        await dispatch(getChats());
      } catch (err) {
        console.log(err);
      }
    }
    companyUsers();
  }, []);

  return { chats, chatsLoading, patientChats, hcpChats };
}
