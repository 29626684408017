import axios from 'axios';
import { Auth } from 'aws-amplify';
import { GET_CANNED_QUESTIONS } from '../../types';
import { CANNED_QUESTIONS_API } from '../../API';

export const getCannedQuestions = (page, filters) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;
  try {
    const res = await axios.get(CANNED_QUESTIONS_API, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      },
      params: {
        email,
        page,
        payload: JSON.stringify(filters),
      },
    });
    if (res && res.data) {
      console.log('Canned', res.data);
      dispatch({
        type: GET_CANNED_QUESTIONS,
        payload: res.data.canned,
        total: res.data.total,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_CANNED_QUESTIONS,
      payload: [],
    });
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      console.log(err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};
