export function newProductFormValidation(name, description, color, type, phoneNumber) {
  const error = { message: '' };

  // Check if email and password exist
  if (!name || name.length <= 2 || name.length > 100) {
    error.message = 'Product name is missing or exceeds the maximum length of 100 characters';
    return error;
  }

  if (!description || description.length <= 2) {
    error.message = 'Product description is missing';
    return error;
  }

  if (!phoneNumber || phoneNumber.length <= 2) {
    error.message = 'Phone number is missing';
    return error;
  }

  if (!type || type.length <= 2) {
    error.message = 'Please select a type';
    return error;
  }

  if (!color || color.length <= 2) {
    error.message = 'Product color is missing';
    return error;
  }

  // If the form is valid, return an error object with an empty message
  error.message = '';
  return error;
}
