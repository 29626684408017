import { Box, Card, CardContent, Typography } from '@mui/material';
import AIAnswer from './AIAnswer';

export default function Chat({ chatData, drawerSize }) {
  console.log('Display chat', chatData);
  const sortedChatData = chatData && chatData.slice().sort((a, b) => a.id - b.id);

  return (
    <CardContent
      sx={{
        height: '75vh',
        overflowY: 'scroll',
        maxWidth: drawerSize,
        width: drawerSize,
        '&::-webkit-scrollbar': {
          width: '0.1em',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <Box>
        {sortedChatData && sortedChatData.length >= 1 ? (
          <>
            {sortedChatData.map((item, index) => (
              <Box
                key={index}
                className={item.ai_answer === null ? 'flex justify-end' : 'flex justify-start'}
                sx={{ alignItems: 'end', mt: 2 }}
              >
                {item.ai_answer === null ? (
                  <></>
                ) : (
                  <>
                    <img
                      src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medical+info/ai-img-6.svg"
                      alt="ai-logo"
                      style={{ borderRadius: '6px', marginRight: '10px' }}
                    />
                  </>
                )}

                <Card
                  sx={{
                    backgroundColor: item.ai_answer === null ? 'black' : '#f2f2f2',
                    color: item.ai_answer === null ? 'white' : 'black',
                    borderRadius: '10px',
                    lineHeight: 1.4,
                    fontSize: '14px',
                    marginLeft: item.ai_answer === null ? '45px' : '0px',
                  }}
                  className={item.ai_answer === null ? 'border-bottom-with-padding' : null}
                >
                  <CardContent className="flex column" style={{ padding: '17px', paddingBottom: '17px' }}>
                    <Typography
                      sx={{
                        color: item.ai_answer === null ? 'white' : 'black',
                        lineHeight: 1.4,
                        fontSize: '14px',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        wordWrap: 'break-word',
                      }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: item.chat }} />
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      <AIAnswer aiAnswer={item.ai_answer} timestamp={item.timestamp} />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            ))}
            {/* <SearchOutputLoading searchOutputLoading={searchOutputLoading} /> */}
          </>
        ) : (
          <>
            <Card>
              <CardContent>Woops, looks like an error occurred! Please close the chat and try again!</CardContent>
            </Card>
          </>
        )}
      </Box>
    </CardContent>
  );
}
