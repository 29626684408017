import { Auth } from 'aws-amplify';

export async function signInUser(email, password) {
  try {
    const user = await Auth.signIn(email, password);
    if (user) {
      return { success: true, user };
    }
    return { success: false, user: null };
  } catch (error) {
    console.log('error signing in', error);
    return { success: false, error };
  }
}
