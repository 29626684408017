import { Box, Breadcrumbs, Button, Container, Typography } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Link, useNavigate } from 'react-router-dom';
import CannedTable from './CannedTable';

export default function CannedList() {
  const navigate = useNavigate();
  return (
    <Container maxWidth="xl">
      <Box className="flex justify-between align-center">
        <Box>
          <Typography variant="h4" sx={{ mb: 1 }}>
            Canned Q/A
          </Typography>
          <Box>
            <Breadcrumbs aria-label="breadcrumb">
              <Link style={{ textDecoration: 'none', color: 'gray' }} underline="hover" color="inherit" to="/app">
                Dashboard
              </Link>
              <Typography color="text.primary">Canned Q/A</Typography>
            </Breadcrumbs>
          </Box>
        </Box>
        <Box>
          <button
            onClick={(e) => navigate('/create-canned-question')}
            type="submit"
            className="w-full inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 hover:from-violet-600 hover:to-blue-600 border border-transparent text-white text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-4 dark:focus:ring-offset-gray-800"
          >
            New Question
          </button>

          {/* <Button
            sx={{ backgroundColor: '#212b36', color: 'white', '&:hover': { backgroundColor: 'black' } }}
            size="large"
            startIcon={<AddRoundedIcon />}
            onClick={(e) => navigate('/create-canned-question')}
          >
            New Question
          </Button> */}
        </Box>
      </Box>

      <Box sx={{ mt: 4 }}>
        <CannedTable />
      </Box>
    </Container>
  );
}
