import { useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LoginPage from './pages/UnAuth/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/dashboard/home/DashboardAppPage';
import UnAuthLayout from './layouts/unAuth/UnAuthLayout';
import Home from './pages/UnAuth/Home';
import ForgotPassword from './pages/UnAuth/forgotPassword';
import Products from './pages/dashboard/products/list/Products';
import CreateProducts from './pages/dashboard/products/create/CreateProducts';
import CannedList from './pages/dashboard/canned/list/CannedList';
import EditProduct from './pages/dashboard/products/edit/EditProduct';
import CreateCannedQuestion from './pages/dashboard/canned/create/CreateCannedQuestion';
import EditCannedQuestion from './pages/dashboard/canned/edit/EditCannedQuestion';
import Chat from './pages/dashboard/chat';
import AIProducts from './pages/dashboard/ai/list/Products';
import TrainAIProduct from './pages/dashboard/ai/train';
import TrainedDocuments from './pages/dashboard/ai/documents';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: <UnAuthLayout />,
      children: [{ path: '/', element: <Home /> }],
    },
    {
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'products', element: <Products /> },
        { path: 'chat', element: <Chat /> },
        { path: 'product/edit/:productId', element: <EditProduct /> },
        { path: 'create-product', element: <CreateProducts /> },
        { path: 'canned', element: <CannedList /> },
        { path: 'create-canned-question', element: <CreateCannedQuestion /> },
        { path: 'canned/edit/:cannedId', element: <EditCannedQuestion /> },
        { path: 'ai/products', element: <AIProducts /> },
        { path: 'ai/train/:productId', element: <TrainAIProduct /> },
        { path: 'ai/trained-documents', element: <TrainedDocuments /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: '/*',
      element: <Page404 />,
    },
  ]);

  return routes;
}
