import { GET_CANNED_QUESTIONS, GET_CANNED_QUESTION } from '../types';

const initialState = {
  cannedQuestions: [],
  cannedQuestionsLoading: true,
  cannedQuestionsTotal: 0,
  cannedSingleQuestion: {},
  cannedSingleQuestionLoading: true,
};

export default function Caller(state = initialState, action) {
  switch (action.type) {
    case GET_CANNED_QUESTION:
      return {
        ...state,
        cannedSingleQuestion: action.payload,
        cannedSingleQuestionLoading: false,
      };
    case GET_CANNED_QUESTIONS:
      return {
        ...state,
        cannedQuestions: action.payload,
        cannedQuestionsLoading: false,
        cannedQuestionsTotal: action.total,
      };
    default:
      return state;
  }
}
