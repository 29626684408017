import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import toast from 'react-hot-toast';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Label from '../../../../components/label/index';
import RichTexEditor from '../../../../components/rich/RichTextEditor';
import { newCannedQuestionFormValidation } from '../../../../helpers/validation/newCannedQuestionFormValidation';
import { editCannedQuestion } from '../../../../store/actions/canned/editCannedQuestion';
import SelectProduct from '../create/SelectProduct';

export default function EditCannedForm({ question }) {
  const [color, setColor] = useState('#ffffff');
  const [questionTitle, setQuestionTitle] = useState('');
  const [answer, setAnswer] = useState('');
  const [type, setType] = useState('');
  const [userName, setUsername] = useState('');
  const [dateCreated, setDateCreated] = useState('');
  const [productId, setProductId] = useState('');
  const [cannedId, setCannedId] = useState('');

  const [status, setStatus] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (question) {
      setQuestionTitle(question.question);
      setUsername(question.full_name);
      setAnswer(question.answer);
      setProductId(question.product_id);
      setStatus(question.question_status);
      setDateCreated(question.date_created);
      setCannedId(question.canned_id);
    }
  }, [question]);

  const productStatus = [
    { label: 'Active', value: 1, color: 'info' },
    { label: 'Draft', value: 0, color: 'error' },
  ];

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleClick = () => {
    const err = newCannedQuestionFormValidation(question, answer, productId);
    if (!err.message) {
      const cannedObj = {
        questionTitle,
        answer,
        productId,
        status,
        cannedId,
      };
      console.log('canned', cannedObj);
      dispatch(editCannedQuestion(cannedObj));
    } else {
      toast.error(err.message || 'Error, please check all your fields');
    }
  };

  return (
    <>
      <Card sx={{ backgroundColor: 'transparent' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box sx={{ mt: 3 }}>
                <SelectProduct productId={productId} setProductId={setProductId} />
              </Box>
            </Grid>

            <Grid item md={12}>
              <Box sx={{ mt: 2 }}>
                <Typography className="regular-text" sx={{ mb: 1 }}>
                  Question
                </Typography>
                <TextField
                  value={questionTitle}
                  fullWidth
                  multiline
                  rows={3}
                  id="outlined-basic"
                  variant="outlined"
                  onChange={(e) => setQuestionTitle(e.target.value)}
                />
              </Box>
            </Grid>

            <Grid item md={12}>
              <Box sx={{ mt: 2 }}>
                <Typography className="regular-text" sx={{ mb: 1 }}>
                  Question Answer
                </Typography>
                <RichTexEditor answer={answer} setAnswer={setAnswer} />
              </Box>
            </Grid>

            <Grid sx={{ mt: 5 }} item md={12}>
              <Box>
                <Typography className="regular-text" sx={{ mb: 1 }}>
                  Question Status
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={status}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={handleStatusChange}
                  >
                    {productStatus ? (
                      productStatus.map((item) => (
                        <MenuItem key={item.label} value={item.value}>
                          <Label color={item.color}>{item.label}</Label>
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            <Grid item md={6}>
              <Box sx={{ mt: 2 }}>
                <Typography className="regular-text" sx={{ mb: 1 }}>
                  Created By
                </Typography>
                <TextField disabled value={userName} fullWidth id="outlined-basic" variant="outlined" />
              </Box>
            </Grid>

            <Grid item md={6}>
              <Box sx={{ mt: 2 }}>
                <Typography className="regular-text" sx={{ mb: 1 }}>
                  Date Created
                </Typography>
                <TextField disabled value={dateCreated} fullWidth id="outlined-basic" variant="outlined" />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box className="flex justify-end">
        <Button
          sx={{ mt: 2, backgroundColor: '#212b36', color: 'white', '&:hover': { backgroundColor: 'black' } }}
          size="large"
          onClick={handleClick}
        >
          Save Changes
        </Button>
      </Box>
    </>
  );
}
