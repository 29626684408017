import axios from 'axios';
import { Auth } from 'aws-amplify';
import { GET_PRODUCT } from '../../types';
import { PRODUCT_API } from '../../API';

export const getProduct = (productId) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;
  try {
    const res = await axios.get(PRODUCT_API, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      },
      params: {
        email,
        productId,
      },
    });
    if (res) {
      console.log('products', res);
      dispatch({
        type: GET_PRODUCT,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_PRODUCT,
      payload: [],
    });
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      console.log(err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};
