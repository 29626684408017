import { Box, Breadcrumbs, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import ViewTrainedDocuments from './helpers/ViewTrainedDocuments';

export default function TrainedDocuments() {
  return (
    <Container maxWidth="xl">
      <Box>
        <Typography variant="h4" sx={{ mb: 1 }}>
          Trained Documents
        </Typography>
        <Box>
          <Breadcrumbs aria-label="breadcrumb">
            <Link style={{ textDecoration: 'none', color: 'gray' }} underline="hover" color="inherit" to="/app">
              Dashboard
            </Link>
            <Link style={{ textDecoration: 'none', color: 'gray' }} underline="hover" color="inherit" to="/ai/products">
              Train AI
            </Link>
            <Typography color="text.primary">Trained Documents</Typography>
          </Breadcrumbs>
        </Box>
      </Box>
      <Box sx={{ mt: 4 }}>
        <ViewTrainedDocuments />
      </Box>
    </Container>
  );
}
