import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardContent, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ScaleLoader } from 'react-spinners';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDispatch, useSelector } from 'react-redux';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { formatString } from '../../../../../helpers/formatString';
// import { clearTrainingTray } from '../../store/actions/train/clearTrainingTray';
import FileTypeImage3D from '../../../../../helpers/FileTypeImage3D';
import { clearTrainingTray } from '../../../../../store/actions/train/clearTrainingTray';

export default function TrainingInProgress() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const trainingInProgress = useSelector((state) => state.training.trainingInProgress);
  const trainingFiles = useSelector((state) => state.training.trainingFiles);
  const trainingCompletedFiles = useSelector((state) => state.training.trainingCompletedFiles);

  const clearTrainedList = () => {
    dispatch(clearTrainingTray());
    setOpen(false);
  };

  useEffect(() => {
    if (trainingInProgress) {
      setOpen(true);
    }
  }, [trainingInProgress]);

  const openButton = () => {
    setOpen(true);
  };

  const closeButton = () => {
    setOpen(false);
  };

  return (
    <>
      <Box className="training-in-progress-popup">
        {open ? (
          <>
            <button type="button" className="button pointer" onClick={closeButton}>
              <KeyboardDoubleArrowDownIcon style={{ marginTop: '5px' }} />
            </button>
          </>
        ) : (
          <>
            <button type="button" onClick={openButton} className="open-button button pointer">
              <CloudUploadIcon
                style={{ marginTop: '5px', animation: trainingInProgress ? 'bounce 1s infinite' : '' }}
              />
            </button>
          </>
        )}
        {open ? (
          <>
            <Card sx={{ mb: 3, bottom: '65px', right: '25px', position: 'fixed', width: '325px' }}>
              <CardContent
                sx={{
                  maxHeight: '500px',
                  overflowY: 'auto',
                }}
              >
                {trainingFiles && trainingFiles.length >= 1 ? (
                  <>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Training In Progress
                    </Typography>
                    {trainingFiles.map((item, index) => (
                      <Card sx={{ border: 'solid 2px gray', mt: 1.6 }} key={index}>
                        <Box className="flex justify-between" sx={{ p: 0.8 }}>
                          <Box className="flex align-center" sx={{ width: '100%' }}>
                            <FileTypeImage3D name={item.name} customWidth="80%" />
                            <Box sx={{ pl: 1, width: '100%' }} className="flex justify-between align-center">
                              <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                {formatString(item.name, 15)}
                              </Typography>
                              <ScaleLoader color="black" size={8} />
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    ))}
                  </>
                ) : trainingCompletedFiles && trainingCompletedFiles.length >= 1 ? (
                  <>
                    <Box className="flex justify-between">
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        Training Completed
                      </Typography>
                      <AutoAwesomeIcon style={{ fontSize: '30px' }} />
                    </Box>

                    {trainingCompletedFiles.map((item, index) => (
                      <Card sx={{ border: 'solid 2px gray', mt: 1.6 }} key={index}>
                        <Box className="flex justify-between" sx={{ p: 0.8 }}>
                          <Box className="flex align-center" sx={{ width: '100%' }}>
                            <FileTypeImage3D name={item.name} customWidth="80%" />
                            <Box sx={{ pl: 1, width: '100%' }} className="flex justify-between align-center">
                              {item.success ? (
                                <>
                                  <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                    {formatString(item.name, 15)}
                                  </Typography>
                                  <CheckCircleIcon style={{ color: '#50C878	' }} />
                                </>
                              ) : (
                                <>
                                  <Box>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                                      {formatString(item.name, 20)}
                                    </Typography>
                                    <Typography sx={{ fontSize: '13px' }}>{item.message}</Typography>
                                  </Box>
                                  <HighlightOffIcon style={{ color: 'red	' }} />
                                </>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    ))}
                    <Box sx={{ mt: 2 }} className="flex justify-center align-center">
                      <Button
                        sx={{
                          fontSize: '17px',
                        }}
                        fullWidth
                        onClick={(e) => navigate('/ai/trained-documents')}
                        variant="outlined"
                      >
                        Documents
                      </Button>
                      <Button
                        sx={{
                          ml: 1.5,
                          fontSize: '17px',
                        }}
                        variant="outlined"
                        fullWidth
                        onClick={clearTrainedList}
                      >
                        Clear
                      </Button>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box sx={{ width: '100%' }} className="flex justify-end">
                      <IconButton onClick={closeButton}>
                        <HighlightOffIcon />
                      </IconButton>
                    </Box>

                    <Box className="flex justify-center column align-center">
                      <Typography sx={{ pt: 1, fontWeight: 600 }}>No training documents available.</Typography>
                      <Typography sx={{ pt: 1, pb: 2, textAlign: 'center', fontSize: '14px' }}>
                        Only documents that are under training will appear here!
                      </Typography>

                      <img
                        src={
                          'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/medaffairsAI/file-upload-v2.svg'
                        }
                        alt="documents"
                      />
                    </Box>
                  </>
                )}
              </CardContent>
            </Card>
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}
