import { Box, Card, CardContent, CardHeader, Skeleton, Typography } from '@mui/material';
import { useGetRecentChatData } from '../../../../hooks/stats/useGetRecentChatData';
import { AppNewsUpdate } from '../../../../sections/@dashboard/app';

export default function RecentChats() {
  const { fullChatData, fullChatDataLoading } = useGetRecentChatData();
  const fiveNumbers = Array.from({ length: 5 }, (_, index) => index + 1);

  if (fullChatDataLoading) {
    return (
      <>
        <Card sx={{ height: '50vh' }}>
          <CardHeader title={'Recent Chats'} subheader={'Click on the product name to see the full chat'} />

          <CardContent>
            {fiveNumbers.map((num, index) => (
              <Box className="flex" key={index} sx={{ mt: 1 }}>
                <Skeleton animation="wave" variant="circular" width={50} height={50} />
                <Box sx={{ width: '80%', ml: 2, mt: 1 }}>
                  <Skeleton animation="wave" height={10} width="80%" />
                  <Skeleton animation="wave" height={10} width="40%" />
                </Box>
              </Box>
            ))}
          </CardContent>
        </Card>
      </>
    );
  }

  if (fullChatData && fullChatData.length === 0) {
    return (
      <Card sx={{ height: '50vh' }}>
        <CardHeader title={'Recent Chats'} subheader={'Click on the product name to see the full chat'} />

        <CardContent>
          <Typography className="text-center">No data yet</Typography>
          <Typography sx={{ fontSize: '12px' }} className="text-center">
            Currently, there is no data available for this chart. Please wait for chats to occur before the data becomes
            visible.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Box>
      <AppNewsUpdate
        title="Recent Chats"
        subheader={'Click on the product name to see the full chat'}
        list={fullChatData}
      />
    </Box>
  );
}
