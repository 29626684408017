const MAIN_API = 'https://o6w4hsi1nd.execute-api.us-east-1.amazonaws.com/default';
const FILE_CURD_API = 'https://7s3a9c0trd.execute-api.us-east-1.amazonaws.com/default';

export const PRODUCTS_API = MAIN_API.concat('/products');
export const PRODUCT_DOCUMENTS_API = MAIN_API.concat('/product/document');
export const DELETE_PINECONE_PRODUCT_DOCUMENTS_API = MAIN_API.concat('/product/document');

export const PRODUCT_API = MAIN_API.concat('/product');
export const ALL_COMPANY_PRODUCTS_API = MAIN_API.concat('/company-products');
export const CANNED_QUESTIONS_API = MAIN_API.concat('/canned-questions');
export const CANNED_QUESTION_API = MAIN_API.concat('/canned-question');
export const TRAIN_API = MAIN_API.concat('/train');

export const DELETE_S3_DOCUMENT_API = FILE_CURD_API.concat('/delete-documents');
export const DOWNLOAD_FILE = FILE_CURD_API.concat('/download');
export const GET_DOCUMENT_UPLOAD_URL = FILE_CURD_API.concat('/upload-url');

export const UPLOAD_DOCUMENT_TO_EXTERNAL_DB_API = MAIN_API.concat('/documents');
export const DELETE_DOCUMENTS_FROM_EXTERNAL_DB_API = MAIN_API.concat('/documents');
export const GET_DOCUMENT_FROM_EXTERNAL_DB_API = MAIN_API.concat('/documents');

export const GET_RECENT_CHATS = MAIN_API.concat('/chat-history/recent');

export const GET_LAST_SEVEN_DAY_CHAT_COUNT = MAIN_API.concat('/stats/last-seven-day-chat-count');
export const GET_PRODUCTS_TALLY = MAIN_API.concat('/stats/product-tally');
export const GET_CHAT_DATA = MAIN_API.concat('/stats/chat-data');
export const GET_FULL_RECENT_CHAT_DATA = MAIN_API.concat('/stats/recent-chat-data');
export const GET_PRODUCT_COUNT = MAIN_API.concat('/stats/product-count');
