import { Box, Breadcrumbs, Button, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import CannedForm from './CannedForm';

export default function CreateCannedQuestion() {
  return (
    <Container maxWidth="xl">
      <Box className="flex justify-between align-center">
        <Box>
          <Typography variant="h4" sx={{ mb: 1 }}>
            Create Canned Q/A
          </Typography>
          <Box>
            <Breadcrumbs aria-label="breadcrumb">
              <Link style={{ textDecoration: 'none', color: 'gray' }} underline="hover" color="inherit" to="/app">
                Dashboard
              </Link>
              <Link style={{ textDecoration: 'none', color: 'gray' }} underline="hover" color="inherit" to="/canned">
                Canned Q/A
              </Link>
              <Typography color="text.primary">Create Canned Question</Typography>
            </Breadcrumbs>
          </Box>
        </Box>
      </Box>

      <Box sx={{ mt: 4 }}>
        <CannedForm />
      </Box>
    </Container>
  );
}
