import { Box } from '@mui/material';
import { BarLoader } from 'react-spinners';

export default function CustomLoader() {
  return (
    <Box
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        height: '58vh',
        width: '100%',
      }}
    >
      <BarLoader color="#084050" width={'250px'} />
    </Box>
  );
}
