import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FileTypeImage3D from '../../../../../helpers/FileTypeImage3D';
import { formatString } from '../../../../../helpers/formatString';
import { deleteDocument } from '../../../../../store/actions/documents/deleteDocuments';
import { getTrainedDocuments } from '../../../../../store/actions/documents/getDocuments';
import DeleteDocument from './DeleteDocument';
import DocumentFilters from './DocumentFilters';
import DownloadFile from './DownloadFile';
import TableRowsLoader from './TableRowsLoader';

const headCells = [
  {
    id: 'document_name',
    numeric: true,
    disablePadding: true,
    label: 'Document Name',
  },
  {
    id: 'product',
    numeric: true,
    disablePadding: true,
    label: 'Product',
  },
  {
    id: 'user',
    numeric: false,
    disablePadding: false,
    label: 'User',
  },
  {
    id: 'document_size',
    numeric: false,
    disablePadding: false,
    label: 'Size',
  },
  {
    id: 'date_created',
    numeric: false,
    disablePadding: false,
    label: 'Date Created',
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'right'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { numSelected, selected, setSelected, setSelectedObjects } = props;
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const dispatch = useDispatch();

  const handleDeleteDialogClickOpen = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const deleteAll = (selected) => {
    const documents = selected.map((item) => ({
      id: item.document_id,
      name: item.document_name,
      productId: item.product_id,
    }));
    setSelectedObjects([]);
    setSelected([]);
    setOpenDeleteDialog(false);
    dispatch(deleteDocument(documents));
  };

  return (
    <Toolbar
      className="flex justify-between"
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <Typography>{''}</Typography>
        </>
      )}

      {numSelected > 0 ? (
        <>
          <Tooltip title="Delete">
            <>
              <IconButton onClick={handleDeleteDialogClickOpen}>
                <DeleteIcon />
              </IconButton>
            </>
          </Tooltip>
          <Dialog
            open={openDeleteDialog}
            onClose={handleDeleteDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {'Are you sure you want to delete the selected documents below?'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {selected &&
                  selected.length >= 1 &&
                  selected.map((item, index) => (
                    <Typography key={index} sx={{ fontWeight: 'bold' }}>
                      {item.document_name}
                    </Typography>
                  ))}
                <Typography sx={{ mt: 2 }}>
                  Confirming the deletion of this document will also exclude it from the AI's training dataset. Are you
                  certain you want to proceed with deleting this document?
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button startIcon={<DeleteIcon />} variant="outlined" onClick={() => deleteAll(selected)}>
                Agree & Delete Documents
              </Button>
              <Button onClick={handleDeleteDialogClose} variant="outlined">
                Disagree
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

export default function ViewTrainedDocuments() {
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [documents, setDocuments] = React.useState([]);
  const [filters, setFilters] = React.useState({});
  const [selectedObjects, setSelectedObjects] = React.useState([]); // Store selected document objects

  const documentDeletionInProgress = useSelector((state) => state.documents.documentDeletionInProgress);
  const trainedDocuments = useSelector((state) => state.documents.trainedDocuments);
  const trainedDocumentsLoading = useSelector((state) => state.documents.trainedDocumentsLoading);

  React.useEffect(() => {
    dispatch(getTrainedDocuments(page, filters));
  }, [page, filters]);

  React.useEffect(() => {
    setDocuments(trainedDocuments.documents);
  }, [trainedDocuments]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = documents && documents.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, document) => {
    event.stopPropagation();
    event.preventDefault();
    const selectedIndex = selected.indexOf(document.document_name);
    const newSelected = [...selected];
    let newSelectedObjects = [...selectedObjects];

    if (selectedIndex === -1) {
      newSelected.push(document.document_name);
      newSelectedObjects.push(document);
    } else {
      newSelected.splice(selectedIndex, 1);
      newSelectedObjects = newSelectedObjects.filter((obj) => obj.id !== document.id);
    }

    setSelected(newSelected);
    setSelectedObjects(newSelectedObjects);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleFilterDispatch = (filterPayload) => {
    setFilters(filterPayload);
    dispatch(getTrainedDocuments(page, filterPayload));
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const visibleRows = React.useMemo(() => {
    if (!documents) {
      return [];
    }
    return documents;
  }, [documents, order, orderBy, page, rowsPerPage]);

  if (trainedDocumentsLoading || documentDeletionInProgress) {
    return <TableRowsLoader rowsNum={8} documentDeletionInProgress={documentDeletionInProgress} />;
  }

  return (
    <Box sx={{ width: '100%', mt: 4, pb: 10 }}>
      <Grid spacing={3} container className="flex justify-center align-center">
        <Grid item md={12} style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <Paper sx={{ width: '100%', mb: 2, mt: 4 }}>
            <DocumentFilters page={page} onFilterApply={handleFilterDispatch} />
            {selected && selected.length >= 1 ? (
              <>
                <EnhancedTableToolbar
                  selected={selectedObjects}
                  numSelected={(selected && selected.length) || 0}
                  setSelected={setSelected}
                  setSelectedObjects={setSelectedObjects}
                />
              </>
            ) : (
              <></>
            )}
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
                <EnhancedTableHead
                  numSelected={(selected && selected.length) || 0}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={(documents && documents.length) || 0}
                />
                <TableBody>
                  {visibleRows &&
                    visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.document_name);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                              onClick={(event) => handleClick(event, row)}
                            />
                          </TableCell>
                          <TableCell sx={{ p: 2 }} component="th" id={labelId} scope="row" padding="none">
                            <Box className="flex align-center">
                              <FileTypeImage3D name={row.document_name} customWidth="30%" />
                              <Box sx={{ pl: 1.4 }}>{formatString(row.document_name, 20)}</Box>
                            </Box>
                          </TableCell>
                          <TableCell
                            style={{
                              maxWidth: '220px',
                            }}
                          >
                            <Box className="flex align-center">
                              <SquareRoundedIcon
                                style={{
                                  fontSize: '30px',
                                  borderRadius: '3px',
                                  backgroundColor: row.product_color || 'white',
                                  color: row.product_color || 'white',
                                }}
                              />
                              <Box sx={{ ml: 1 }}>
                                <Typography sx={{ fontSize: '14px' }}>
                                  {formatString(row.product_name || '', 45)}
                                </Typography>
                              </Box>
                            </Box>
                          </TableCell>
                          <TableCell align="right">
                            <Typography sx={{ fontSize: '14px' }}>{formatString(row.full_name, 15)}</Typography>
                            {formatString(row.email, 24)}
                          </TableCell>
                          <TableCell align="right">
                            <Typography sx={{ fontSize: '14px' }}>{row.document_size}</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <Typography sx={{ fontSize: '14px' }}>{row.date_created}</Typography>
                          </TableCell>
                          <TableCell align="right">
                            <DownloadFile file={row} />
                            <DeleteDocument file={row} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[8]}
              component="div"
              count={trainedDocuments ? trainedDocuments.total : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
        </Grid>
      </Grid>
    </Box>
  );
}
