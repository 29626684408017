import { Box, Typography } from '@mui/material';
import Label from '../../../../components/label';
import { formatTimestamp } from '../../../../helpers/formatTimestamp';

export default function AIAnswer({ aiAnswer, timestamp }) {
  return aiAnswer && aiAnswer === 1 ? (
    <Box className="flex justify-between align-center">
      <Label color="primary">AI Answer</Label>
      <Typography sx={{ fontSize: '12px' }}>{formatTimestamp(timestamp)}</Typography>
    </Box>
  ) : aiAnswer === 0 ? (
    <Box className="flex justify-between align-center">
      <Label color="info">Canned Answer</Label>
      <Typography sx={{ fontSize: '12px' }}>{formatTimestamp(timestamp)}</Typography>
    </Box>
  ) : (
    <></>
  );
}
