import { Box, Breadcrumbs, Button, Card, CardContent, CardHeader, Container, Typography } from '@mui/material';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { useEffect } from 'react';
import ArticleIcon from '@mui/icons-material/Article';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getProduct } from '../../../../store/actions/products/getProduct';
import CustomDropZone from './helpers/CustomDropZone';
import CustomLoader from '../../../../components/ui/CustomLoader';

export default function TrainAIProduct() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const productLoading = useSelector((state) => state.product.productLoading);
  const product = useSelector((state) => state.product.product);

  useEffect(() => {
    if (productId) {
      dispatch(getProduct(productId));
    }
  }, [productId]);

  if (productLoading) {
    return <CustomLoader />;
  }

  if (product && product.success === false) {
    return (
      <Box>
        <Card>
          <CardHeader
            title={'Product Not Found! Please try again'}
            subheader={'Hello, the product your are looking for is not found. Please refresh the page, or try again'}
          />
          <CardContent>
            <Box className="flex justify-center align-center">
              <img src="/assets/illustrations/illustration_404.svg" alt="404" />
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Container maxWidth="xl">
      <Box>
        <Typography variant="h4" sx={{ mb: 1 }}>
          Train AI
        </Typography>
      </Box>
      <Box>
        <Breadcrumbs aria-label="breadcrumb">
          <Link style={{ textDecoration: 'none', color: 'gray' }} underline="hover" color="inherit" to="/app">
            Dashboard
          </Link>
          <Link style={{ textDecoration: 'none', color: 'gray' }} underline="hover" color="inherit" to="/ai/products">
            Products
          </Link>
          <Typography color="text.primary">Train AI</Typography>
        </Breadcrumbs>
      </Box>

      <Box sx={{ mt: 8 }}>
        <Box className="flex align-center justify-between">
          <Box className="flex">
            <SquareRoundedIcon
              style={{
                fontSize: '40px',
                borderRadius: '3px',
                backgroundColor: product.product.product_color || 'white',
                color: product.product.product_color || 'white',
              }}
            />
            <Box sx={{ ml: 2 }}>
              <Typography variant="h5">{product.product.product_name || ''}</Typography>
              <Typography sx={{ color: 'gray' }}>Supported formats: pdf, txt, csv, xls, png, jpg, jpeg</Typography>
            </Box>
          </Box>
          <Box>
            <button
              onClick={(e) => navigate('/ai/trained-documents')}
              type="submit"
              className="w-full inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 hover:from-violet-600 hover:to-blue-600 border border-transparent text-white text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-4 dark:focus:ring-offset-gray-800"
            >
              View Trained Documents
            </button>
            {/* <Button
              sx={{ backgroundColor: '#212b36', color: 'white', '&:hover': { backgroundColor: 'black' } }}
              size="large"
              startIcon={<ArticleIcon />}
              onClick={(e) => navigate('/ai/trained-documents')}
            >
              View Trained Documents
            </Button> */}
          </Box>
        </Box>

        <Box sx={{ pb: 5 }}>
          <Box className="flex justify-center align-center">
            <CustomDropZone product={product.product} />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
