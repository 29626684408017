import { useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { deleteDocument } from '../../../../../store/actions/documents/deleteDocuments';

export default function DeleteDocument({ file }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const handleDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(deleteDocument([{ id: file.document_id, name: file.document_name, productId: file.product_id }]));
    setOpen(false);
  };

  return (
    <>
      <IconButton style={{ marginLeft: '10px', color: '#ff5630' }} onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure you wish to delete the document below?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>
              <span style={{ fontWeight: 'bold', color: '#ff5630' }}>{file.document_name}</span> will be deleted from
              the training of product - <span style={{ fontWeight: 'bold' }}>{file.product_name}</span>
            </Typography>

            <Typography sx={{ mt: 2 }}>
              Confirming the deletion of this document will also exclude it from the AI's training dataset. Are you
              certain you want to proceed with deleting this document?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button startIcon={<DeleteIcon />} onClick={handleDelete} autoFocus variant="outlined">
            Agree & Delete Document
          </Button>
          <Button onClick={handleClose} variant="outlined">
            Disagree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
