import SortRoundedIcon from '@mui/icons-material/SortRounded';
import PropTypes from 'prop-types';
// @mui
import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import useResponsive from '../../../hooks/useResponsive';
import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav, desktopWidth, setDesktopWidth, handleClick, loading, user }) {
  const isDesktop = useResponsive('up', 'md');
  const NAV_WIDTH = desktopWidth;

  const HEADER_MOBILE = 64;

  const HEADER_DESKTOP = 92;

  const StyledRoot = styled(AppBar)(({ theme }) => ({
    ...bgBlur({ color: theme.palette.background.default }),
    boxShadow: 'none',
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    },
  }));

  const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    minHeight: HEADER_MOBILE,
    [theme.breakpoints.up('lg')]: {
      minHeight: HEADER_DESKTOP,
      padding: theme.spacing(0, 5),
    },
  }));

  return (
    <StyledRoot style={{ backgroundColor: 'white' }}>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        {isDesktop ? (
          <>
            <IconButton onClick={handleClick}>
              <SortRoundedIcon style={{ color: 'black' }} />
            </IconButton>
          </>
        ) : (
          <></>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <AccountPopover loading={loading} user={user} />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
