import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import toast from 'react-hot-toast';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import SendIcon from '@mui/icons-material/Send';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card, Chip, Grid, InputAdornment, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyProducts } from '../../../../store/actions/products/getProducts';
import { formatString } from '../../../../helpers/formatString';
import Label from '../../../../components/label';
import { deleteProduct } from '../../../../store/actions/products/deleteProduct';
import CustomLoader from '../../../../components/ui/CustomLoader';
import ProductFilters from './helpers/ProductFilters';

const headCells = [
  {
    id: 'product_name',
    numeric: false,
    disablePadding: false,
    label: 'Product Name',
  },
  {
    id: 'product_description',
    numeric: false,
    disablePadding: false,
    label: 'Product Description',
  },
  {
    id: 'publish',
    numeric: true,
    disablePadding: false,
    label: 'Publish',
  },
  {
    id: 'created_by',
    numeric: true,
    disablePadding: false,
    label: 'Created By',
  },
  {
    id: 'created_at',
    numeric: true,
    disablePadding: false,
    label: 'Created On',
  },
  {
    id: '',
    numeric: true,
    disablePadding: false,
    label: '',
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function ProductsTable({ clickAction }) {
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [visibleRows, setVisibleRows] = React.useState([]);
  const [deleteRow, setDeleteRow] = React.useState('');
  const [filters, setFilters] = React.useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productsList = useSelector((state) => state.products.productsList);
  const productsLoading = useSelector((state) => state.products.productsLoading);
  const productsLength = useSelector((state) => state.products.productsTotal);

  React.useEffect(() => {
    dispatch(getCompanyProducts(page, filters));
  }, [page, filters]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleFilterDispatch = (filterPayload) => {
    setFilters(filterPayload);
    dispatch(getCompanyProducts(page, filters));
  };

  React.useEffect(() => {
    setVisibleRows(productsList);
  }, [productsList]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleDelete = (product) => {
    setDeleteRow(product);
    handleClickOpen();
  };

  const handleDeleteProduct = () => {
    if (deleteRow && deleteRow.product_id) {
      dispatch(deleteProduct(deleteRow.product_id));
      handleClose();
    } else {
      toast.error('Unable to delete row. Please try again');
    }
  };

  if (productsLoading) {
    return <CustomLoader />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
        }}
      >
        <ProductFilters page={page} onFilterApply={handleFilterDispatch} />

        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
            <EnhancedTableHead rowCount={productsList && productsList.length} />
            <TableBody>
              {visibleRows &&
                visibleRows.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      onClick={(e) =>
                        clickAction === 'products'
                          ? navigate(`/product/edit/${row.product_id}`)
                          : navigate(`/ai/train/${row.product_id}`)
                      }
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.product_name}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell
                        style={{
                          maxWidth: '240px',
                        }}
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        <Box className="flex align-center">
                          <SquareRoundedIcon
                            style={{
                              fontSize: '40px',
                              borderRadius: '3px',
                              backgroundColor: row.product_color || 'white',
                              color: row.product_color || 'white',
                            }}
                          />
                          <Box sx={{ ml: 2 }}>
                            <Typography className="table-title-value-text">
                              {formatString(row.product_name || '', 26)}
                            </Typography>
                            <Typography className="table-title-sub-value-text">
                              {formatString(row.product_type || '', 26)}{' '}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell
                        style={{
                          maxWidth: '120px',
                        }}
                      >
                        <Typography className="basic-text">
                          {formatString(row.product_description || '', 80)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Label color={row.product_status === 1 ? 'info' : 'error'}>
                          {row.product_status === 1 ? 'ACTIVE' : 'DRAFT'}
                        </Label>
                      </TableCell>
                      <TableCell align="right">
                        <Box>
                          <Typography className="basic-text">{formatString(row.full_name || '', 14)}</Typography>
                          <Typography className="table-title-sub-value-text">
                            {formatString(row.email || '', 20)}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <Box className="flex justify-end align-center">
                          <CalendarMonthIcon style={{ color: 'gray' }} />
                          <Typography className="basic-text" sx={{ ml: 1 }}>
                            {row.date_created}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            handleDelete(row);
                          }}
                          aria-label="more"
                        >
                          <DeleteIcon style={{ color: '#ff5630' }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[8]}
          component="div"
          count={productsLength || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete?'}</DialogTitle>
        <DialogContent>
          <Typography id="alert-dialog-description">
            Are you sure you want to proceed with deleting this product?
          </Typography>
          <DialogContentText sx={{ mt: 2 }}>
            <b style={{ color: '#ff5630' }}>
              Deleting this product will delete all canned questions & trained documents related to this product.
            </b>{' '}
            Please note that this action cannot be undone once the deletion is confirmed.
          </DialogContentText>
          <DialogContentText sx={{ mt: 2 }}>
            <span style={{ textDecoration: 'bold', fontWeight: 600 }}>Product Name - </span>
            <b style={{ color: '#ff5630' }}>{deleteRow && deleteRow.id ? deleteRow.product_name : ''} </b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteProduct} variant="outlined">
            Delete
          </Button>
          <Button onClick={handleClose} autoFocus variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
