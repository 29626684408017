import Button from '@mui/material/Button';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Card,
  CardActionArea,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { formatString } from '../../../../../helpers/formatString';
import { useGetCompanyProducts } from '../../../../../hooks/useGetCompanyProducts';
import Label from '../../../../../components/label/Label';

export default function ProductsSelectButton({
  removeAll,
  setRemoveAll,
  usersRemove,
  setUsersRemove,
  selectedUsersFinal,
  setSelectedUsersFinal,
}) {
  const { allCompanyProducts, allCompanyProductsLoading } = useGetCompanyProducts();
  const [open, setOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleProductSelect = (item) => {
    if (selectedProducts && selectedProducts.includes(item)) {
      setSelectedProducts(selectedProducts.filter((user) => user !== item));
    } else {
      setSelectedProducts([...selectedProducts, item]);
    }
  };

  const handleRemoveItem = (item) => {
    if (selectedProducts && selectedProducts.includes(item)) {
      setSelectedProducts(selectedProducts.filter((user) => user !== item));
    }
  };

  const handleRemoveAll = () => {
    setSelectedProducts([]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = () => {
    setSelectedUsersFinal(selectedProducts);
    setOpen(false);
  };

  useEffect(() => {
    if (usersRemove) {
      if (selectedProducts && selectedProducts.includes(usersRemove)) {
        setSelectedProducts(selectedProducts.filter((user) => user !== usersRemove));
      }
    }
    return () => {
      setUsersRemove({});
    };
  }, [usersRemove]);

  useEffect(() => {
    if (removeAll) {
      setSelectedProducts([]);
    }
    return () => {
      setRemoveAll(false);
    };
  }, [removeAll]);

  if (allCompanyProductsLoading) {
    return <Skeleton variant="rounded" width={100} height={40} />;
  }

  return (
    <Box>
      <Button endIcon={<KeyboardArrowDownIcon />} onClick={handleClickOpen}>
        Products
      </Button>
      <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-slide-description">
        <Box>
          <DialogTitle>{'Select Products to Filter By'}</DialogTitle>
          <DialogContent>
            {allCompanyProductsLoading ? (
              <>
                <Skeleton variant="rectangular" width={410} height={80} />
              </>
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item md={12} sx={{ mt: 2, width: '100%' }}>
                    <TextField
                      id="outlined-basic"
                      label="Search Product"
                      variant="outlined"
                      fullWidth
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </Grid>
                  {selectedProducts && selectedProducts.length >= 1 ? (
                    <Grid item md={12} xs={12} sx={{ width: '100%' }}>
                      <Card sx={{ width: '100%', border: 'dashed 1px black' }}>
                        <Box sx={{ p: 2 }}>
                          <Box className="flex justify-between align-center">
                            <Typography sx={{ fontWeight: 'bold' }}>Selected Products</Typography>
                            <Button variant="outlined" startIcon={<DeleteIcon />} onClick={handleRemoveAll}>
                              Clear All
                            </Button>
                          </Box>
                          {selectedProducts.map((item, index) => (
                            <Chip
                              variant="outlined"
                              onDelete={() => handleRemoveItem(item)}
                              sx={{ mt: 1.5, ml: 1 }}
                              label={item.product_name}
                              key={index}
                            />
                          ))}
                        </Box>
                      </Card>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid
                    item
                    md={12}
                    className="hide-scrollbar"
                    sx={{
                      overflowY: 'scroll',
                      maxHeight: '310px',
                      '&::-webkit-scrollbar': {
                        width: '0.1em',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                      },
                    }}
                  >
                    <Grid container spacing={1}>
                      {allCompanyProducts &&
                        allCompanyProducts.length >= 1 &&
                        allCompanyProducts
                          .filter((item) => item.product_name.toLowerCase().includes(searchQuery.toLowerCase()))
                          .map((item, index) => (
                            <Grid item md={12} xs={12} key={index} sx={{ mt: 1, width: '100%' }}>
                              <Card
                                sx={{
                                  width: '100%',
                                  backgroundColor:
                                    selectedProducts && selectedProducts.includes(item) ? '#dddddd' : 'inherit', // Use 'inherit' for the default background color
                                }}
                              >
                                <CardActionArea onClick={(e) => handleProductSelect(item)}>
                                  <Box sx={{ p: 2 }}>
                                    <Box className="flex align-center">
                                      <SquareRoundedIcon
                                        style={{
                                          fontSize: '30px',
                                          borderRadius: '3px',
                                          backgroundColor: item.product_color || 'white',
                                          color: item.product_color || 'white',
                                          marginRight: '8px',
                                        }}
                                      />
                                      <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                                        {formatString(item.product_name, 50)}
                                      </Typography>
                                      <Label sx={{ ml: 2 }} color={item.product_status === 1 ? 'info' : 'error'}>
                                        {item.product_status === 1 ? 'ACTIVE' : 'DRAFT'}
                                      </Label>
                                    </Box>
                                  </Box>
                                </CardActionArea>
                              </Card>
                            </Grid>
                          ))}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={handleSelect}
              variant={selectedProducts && selectedProducts.length >= 1 ? 'contained' : 'outlined'}
            >
              Select
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}
