import axios from 'axios';
import { Auth } from 'aws-amplify';
import { FULL_CHAT_DATA } from '../../types';
import { GET_FULL_RECENT_CHAT_DATA } from '../../API';

export const getRecentChatData =
  (page = 0, filters) =>
  async (dispatch) => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const email = user.attributes.email;

    try {
      const res = await axios.get(GET_FULL_RECENT_CHAT_DATA, {
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer '.concat(token),
        },
        params: {
          email,
          page,
          payload: JSON.stringify(filters),
        },
      });
      if (res) {
        console.log('FULL_CHAT_DATA', res);
        dispatch({
          type: FULL_CHAT_DATA,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: FULL_CHAT_DATA,
        payload: [],
      });
      if (err.response) {
        // The client was given an error response (5xx, 4xx)
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else if (err.request) {
        console.log(err.request);
        // The client never received a response, and the request was never left
      } else {
        console.log('Error', err.message);
      }
    }
  };
