import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRecentChatData } from '../../store/actions/stats/getRecentChatData';

export function useGetRecentChatData() {
  const fullChatData = useSelector((state) => state.stats.fullChatData);
  const fullChatDataLoading = useSelector((state) => state.stats.fullChatDataLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    async function companyUsers() {
      try {
        const filters = {
          products: [],
          query: '',
          types: [],
          startDate: '',
          endDate: '',
        };
        await dispatch(getRecentChatData(0, filters));
      } catch (err) {
        console.log(err);
      }
    }
    companyUsers();
  }, []);

  return { fullChatData, fullChatDataLoading };
}
