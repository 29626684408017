/* eslint-disable */

// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:d8cab712-a246-470e-9320-83308ebf0e62',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_2HXa6GkBT',
  aws_user_pools_web_client_id: '3l9td7cepffkfp0o7stau13i8l',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL', 'NAME'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
};
export default awsmobile;
